import {
  canHaveSubAnswers,
  hasTextAnswer,
  questionTypeCodes,
  questionTypeHeights,
  questionTypeOptionsShort,
} from "../../shared/hoc/pagesWeb/incidentReporter/constants";

export const noAnswerProvidedText = "No Answer Provided";

export const validateEmail = (email) =>
  String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

export const validateAnswer = (answer, question, answerErrorSetter) => {
  const { typeCode, requiresAnswer } = question;
  if (!requiresAnswer) {
    answerErrorSetter(false);
    return false;
  }
  if (typeCode === questionTypeCodes.multiple) {
    if (answer.answerOptionIDs.length < 1 && !answer.answerOptionUUID.trim()) {
      answerErrorSetter(true);
      return true;
    }
    answerErrorSetter(false);
    return false;
  }

  if (typeCode === questionTypeCodes.dropdown) {
    if (!answer.answerOptionUUID.trim()) {
      answerErrorSetter(true);
      return true;
    }
    answerErrorSetter(false);
    return false;
  }
  if (
    typeCode === questionTypeCodes.text ||
    typeCode === questionTypeCodes.paragraph
  ) {
    if (!answer.text.trim()) {
      answerErrorSetter(true);
      return true;
    }
    answerErrorSetter(false);
    return false;
  }
  if (typeCode === questionTypeCodes.calendar) {
    if (!answer.selectedDate) {
      answerErrorSetter(true);
      return true;
    }
    answerErrorSetter(false);
    return false;
  }
  return true;
};

export const getNextSubQuestionFromParent = (
  submittedAnswer,
  form,
  setDisplayQuestion
) => {
  const { questions } = form;
  const { formQuestion } = submittedAnswer;
  const parentQuestion = questions[formQuestion.questionIdx];
  for (let i = 0; i < submittedAnswer?.answerOptionIDs?.length; i += 1) {
    const answerOptionID = submittedAnswer.answerOptionIDs[i];
    const { answerOptions } = parentQuestion;
    const answerOptionIdx = answerOptions.findIndex(
      (object) => object.uuid === answerOptionID
    );
    if (
      answerOptions[answerOptionIdx]?.subQuestions &&
      answerOptions[answerOptionIdx]?.subQuestions.length !== 0
    ) {
      const nextDisplayQuestion = {
        ...formQuestion,
        answerOptionIdx,
        subQuestionIdx: 0,
        parentRemainingAnswerIds: submittedAnswer.answerOptionIDs.slice(i + 1),
      };
      setDisplayQuestion(nextDisplayQuestion);
      return answerOptions[answerOptionIdx].subQuestions[0];
    }
  }
  if (submittedAnswer.answerOptionUUID) {
    const { answerOptions } = parentQuestion;
    const answerOptionIdx = answerOptions.findIndex(
      (object) => object.uuid === submittedAnswer.answerOptionUUID
    );
    if (answerOptionIdx !== null) {
      const nextDisplayQuestion = {
        ...formQuestion,
        answerOptionIdx,
        subQuestionIdx: 0,
        parentRemainingAnswerIds: [],
      };
      setDisplayQuestion(nextDisplayQuestion);
      return answerOptions[answerOptionIdx].subQuestions[0];
    }
  }
  return null;
};

export const getNextSubQuestion = (
  submittedAnswer,
  form,
  setDisplayQuestion
) => {
  const { questions } = form;
  const { formQuestion } = submittedAnswer;
  const parentQuestion = questions[formQuestion.questionIdx];
  const { subQuestionIdx, parentRemainingAnswerIds } = formQuestion;
  if (
    parentQuestion.answerOptions[formQuestion.answerOptionIdx].subQuestions
      .length >
    formQuestion.subQuestionIdx + 1
  ) {
    // Get the next sub question
    const nextDisplayQuestion = {
      ...formQuestion,
      subQuestionIdx: subQuestionIdx + 1,
    };
    setDisplayQuestion(nextDisplayQuestion);

    return parentQuestion.answerOptions[formQuestion.answerOptionIdx]
      .subQuestions[subQuestionIdx + 1];
  }
  if (parentRemainingAnswerIds.length === 0) {
    const nextDisplayQuestion = {
      ...formQuestion,
      answerOptionIdx: null,
      subQuestionIdx: null,
      parentRemainingAnswerIds: [],
      questionIdx: formQuestion.questionIdx + 1,
    };
    setDisplayQuestion(nextDisplayQuestion);
    return form.questions[formQuestion.questionIdx + 1];
  }
  for (let i = 0; i < parentRemainingAnswerIds.length; i += 1) {
    const answerOptionID = parentRemainingAnswerIds[i];
    const { answerOptions } = parentQuestion;
    const answerOptionIdx = answerOptions.findIndex(
      (object) => object.uuid === answerOptionID
    );
    if (answerOptions[answerOptionIdx].subQuestions?.length !== 0) {
      const remainingIds = parentRemainingAnswerIds.slice(i + 1);
      const nextDisplayQuestion = {
        ...formQuestion,
        answerOptionIdx,
        subQuestionIdx: 0,
        parentRemainingAnswerIds: remainingIds,
      };
      setDisplayQuestion(nextDisplayQuestion);
      return parentQuestion.answerOptions[answerOptionIdx].subQuestions[0];
    }
  }
  return null;
};

export const getNextQuestion = (
  submittedAnswer,
  form,
  setDisplayQuestion,
  displayQuestion
) => {
  const { questions } = form;
  const { formQuestion } = submittedAnswer;
  const parentQuestion = questions[formQuestion.questionIdx];
  const { subQuestionIdx, questionIdx } = formQuestion;

  // If parent question and can have a sub
  if (
    subQuestionIdx === null &&
    canHaveSubAnswers.includes(parentQuestion.typeCode)
  ) {
    const nextSubQuestionFromParent = getNextSubQuestionFromParent(
      submittedAnswer,
      form,
      setDisplayQuestion
    );

    if (nextSubQuestionFromParent) {
      return nextSubQuestionFromParent;
    }

    const nextDisplayQuestion = {
      ...formQuestion,
      questionIdx: questionIdx + 1,
    };
    setDisplayQuestion(nextDisplayQuestion);
    return questions[questionIdx + 1];
  }
  // Check if there are more sub questions
  if (subQuestionIdx !== null) {
    // Add check for next under current sub question

    const nextSubQuestion = getNextSubQuestion(
      submittedAnswer,
      form,
      setDisplayQuestion
    );
    if (nextSubQuestion) {
      return nextSubQuestion;
    }
    const nextDisplayQuestion = {
      ...formQuestion,
      answerOptionIdx: null,
      subQuestionIdx: null,
      parentRemainingAnswerIds: [],
      questionIdx: questionIdx + 1,
    };
    setDisplayQuestion(nextDisplayQuestion);
    return form.questions[questionIdx + 1];
  }

  // If is sub find next question
  // Determine next question based only on submittedAnswer?
  const nextDisplayQuestion = {
    ...formQuestion,
    answerOptionIdx: null,
    subQuestionIdx: null,
    parentRemainingAnswerIds: [],
    questionIdx: questionIdx + 1,
  };
  setDisplayQuestion(nextDisplayQuestion);
  return form.questions[displayQuestion.questionIdx + 1];
};

export const getPageQuestionsArrs = (questionsArr, height) => {
  const pagesArr = [[]];
  let questionsHeight = 0;
  questionsArr.forEach((question) => {
    const type = questionTypeOptionsShort[question.typeCode];
    const heightObject = questionTypeHeights[type];
    const questionHeight =
      heightObject.baseHeight +
      heightObject.questionHeight * question.answerOptions.length;
    questionsHeight += questionHeight;
    if (questionsHeight < height) {
      pagesArr[pagesArr.length - 1].push(question);
    } else {
      pagesArr.push([question]);
      questionsHeight = questionHeight;
    }
  });
  return pagesArr;
};

export const getPreviousQuestionAndAnswer = (
  form,
  formSubmit,
  displayQuestion
) => {
  const _question = getPreviousFormQuestion(form, displayQuestion, formSubmit);
  const { _answer, _displayQuestion } = getQuestionAnswer(
    _question.uuid,
    formSubmit
  );

  return {
    _answer,
    _question,
    _displayQuestion,
  };
};

const getPreviousFormQuestion = (form, displayQuestion, formSubmit) => {
  const {
    questionIdx,
    answerOptionIdx,
    subQuestionIdx,
    parentRemainingAnswerIds,
  } = displayQuestion;
  const { questions } = form;

  if (answerOptionIdx === null && subQuestionIdx === null) {
    // Get previous parent question's sub question
    const previousParent = questions[questionIdx - 1];
    const previousParentAnswer = formSubmit.answers.find(
      (answer) => answer.questionUUID === previousParent.uuid
    );
    const { answerOptions } = previousParent;

    for (
      let i = previousParentAnswer.answerOptionIDs.length - 1;
      i >= 0;
      i -= 1
    ) {
      const previousParentAnswerOptionID =
        previousParentAnswer.answerOptionIDs[i];

      const answerOption = answerOptions.find(
        (option) => option.uuid === previousParentAnswerOptionID
      );

      if (answerOption?.subQuestions.length > 0) {
        const totalSubQuestions = answerOption?.subQuestions.length;
        return answerOption?.subQuestions[totalSubQuestions - 1];
      }
    }

    // Get previous parent question
    if (questionIdx > 0) {
      return previousParent;
    }
  }
  if (subQuestionIdx >= 0) {
    const parentQuestion = questions[questionIdx];
    const parentAnswerAndDisplayQuestion = getQuestionAnswer(
      parentQuestion.uuid,
      formSubmit
    );
    const parentAnswerOptionIDs =
      parentAnswerAndDisplayQuestion._answer.answerOptionIDs;

    const previousIdx = parentAnswerOptionIDs.findIndex(
      (object) => object === parentRemainingAnswerIds[0]
    );
    // get parent question if we are
    // at the beginning of the sub questions
    if (subQuestionIdx > 0) {
      return questions[questionIdx].answerOptions[answerOptionIdx].subQuestions[
        subQuestionIdx - 1
      ];
    }
    if (previousIdx === -1) {
      // This needs to be in a reverse for loop
      for (let i = parentAnswerOptionIDs.length - 2; i >= 0; i -= 1) {
        const previousAnswerOptionIdx = questions[
          questionIdx
        ].answerOptions.findIndex(
          (option) => option.uuid === parentAnswerOptionIDs[i]
        );

        const previousAnswerOption =
          questions[questionIdx].answerOptions[previousAnswerOptionIdx];

        if (previousAnswerOption && displayQuestion.subQuestionIdx >= 0) {
          const previousQuestion =
            previousAnswerOption.subQuestions[
              previousAnswerOption.subQuestions.length - 1
            ];

          if (previousQuestion) {
            return previousQuestion;
          }
        }
      }
    }
    if (previousIdx - 2 < 0) {
      if (subQuestionIdx === 0) {
        return parentQuestion;
      }
    } else {
      const previousAnswerOptionId = parentAnswerOptionIDs[previousIdx - 2];
      const previousAnswerOption = questions[questionIdx].answerOptions.find(
        (ao) => ao.uuid === previousAnswerOptionId
      );

      return previousAnswerOption.subQuestions[
        previousAnswerOption.subQuestions.length - 1
      ];
    }
  }
  return null;
};

export const getQuestionAnswer = (questionId, formSubmit) => {
  const { answers } = formSubmit;
  const answerIdx = answers.findIndex(
    (object) => object.questionUUID === questionId
  );

  const _answer = answers[answerIdx];
  const _displayQuestion = _answer?.formQuestion;

  return { _answer, _displayQuestion };
};

export const getAnswerText = (question, answer) => {
  if (hasTextAnswer.includes(question.typeCode)) {
    return answer.text || noAnswerProvidedText;
  }
  if (answer.answerOptionUUID) {
    const answerOption = question.answerOptions.find(
      (ao) => ao.uuid === answer.answerOptionUUID
    );
    return answerOption.text;
  }
  if (answer.selectedDate) {
    return getFormattedDate(answer.selectedDate);
  }
  if (answer.answerOptionIDs.length > 0) {
    let answerString = "";
    answer.answerOptionIDs.forEach((id, idx) => {
      const answerOption = question.answerOptions.find((ao) => ao.uuid === id);
      answerString += `${idx + 1}, ${answerOption.text}\n`;
    });
    return answerString;
  }
  return noAnswerProvidedText;
};

export const getFormattedDate = (date) => {
  const year = date.getFullYear();

  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : `0${month}`;

  let day = date.getDate().toString();
  day = day.length > 1 ? day : `0${day}`;

  return `${month}/${day}/${year}`;
};

export const showSummarySubQuestions = (q, a, summaryQuestion) => {
  if (a.answerOptionIDs.length > 0) {
    const qna = a.answerOptionIDs.map((id) => {
      const answerOption = q.answerOptions.find((ao) => ao.uuid === id);

      return answerOption.subQuestions.map((sub) => summaryQuestion(sub));
    });
    return qna;
  }
  if (a.answerOptionUUID) {
    const answerOption = q.answerOptions.find(
      (ao) => ao.uuid === a.answerOptionUUID
    );
    const qna = answerOption.subQuestions.map((sub) => summaryQuestion(sub));

    return qna;
  }
  return "";
};

export const recursiveOrderQuestions = (arr, aoUUID = null) => {
  arr.forEach((question, idx) => {
    // eslint-disable-next-line no-param-reassign
    question.order = idx;
    // eslint-disable-next-line no-param-reassign
    question.answerOptionUUID = aoUUID;
    question.answerOptions.forEach((answer) => {
      recursiveOrderQuestions(answer.subQuestions, answer.uuid);
    });
  });
  return arr;
};

export const getTargetPath = (targetItem) => {
  let { questionIdx } = targetItem;
  let targetObject = { ...targetItem };
  const targetPath = [targetObject];

  while (questionIdx === undefined) {
    const { parentObject } = targetObject;
    targetObject = parentObject;
    questionIdx = targetObject?.questionIdx;
    targetPath.push({ ...targetObject });
  }

  return targetPath;
};

export const checkPathsEqual = (addPath, removePath) => {
  for (let i = 0; i < addPath.length; i += 1) {
    if (
      addPath[i]?.answerIdx !== removePath[i].answerIdx ||
      addPath[i]?.subIdx !== removePath[i].subIdx ||
      addPath[i]?.questinoIdx !== removePath[i].questinoIdx
    ) {
      return false;
    }
  }

  return true;
};

export const setFormStructure = (rawForm) => {
  const structuredForm = rawForm;
  structuredForm.questionsList = rawForm.questions;
  const structuredQuestions = setFormQuestions(rawForm.questions);
  structuredForm.questions = structuredQuestions;
  return structuredForm;
};

export const setFormQuestions = (_questionsArr) => {
  const tempArr = [];
  _questionsArr.forEach((question) => {
    if (question.answerOptionsQuestions?.length === 0) {
      const q = setAnswerOptionQuestions(question, _questionsArr);
      tempArr.push(q);
    }
  });

  return tempArr;
};

const setAnswerOptionQuestions = (question, _questionsArr) => {
  if (canHaveSubAnswers.includes(question?.typeCode)) {
    question.answerOptions.forEach((ao) => {
      let q;
      if (ao.answerOptionsQuestions.length > ao.subQuestions.length) {
        ao.answerOptionsQuestions.forEach((aoq) => {
          q = _questionsArr.find((_q) => _q.uuid === aoq.questionUUID);
          q = setAnswerOptionQuestions(q, _questionsArr);
          if (q) {
            ao.subQuestions.push(q);
          }
        });
      }
    });
  }
  return question;
};
