import { useEffect, useState } from "react";

import { apiGetForm } from "../../shared/hoc/pagesWeb/incidentReporter/utils";
import { setFormStructure } from "../pages/utils";

function useForm(formID) {
  const [form, setForm] = useState({});
  const [loadingForm, setLoadingForm] = useState(false);
  const [formError, setFormError] = useState();

  const getForm = async () => {
    try {
      setLoadingForm(true);
      const response = await apiGetForm(formID);
      setFormStructure(response);
      setForm(response);
      setLoadingForm(false);
    } catch (e) {
      console.error(e);
      setFormError(e);
    }
  };

  useEffect(() => {
    getForm();
  }, []);

  useEffect(() => {
    console.log({ form });
  }, [form]);

  return { form, loadingForm, formError, setForm };
}

export default useForm;
