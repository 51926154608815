import {
  Typography,
  Box,
  ListItemText,
  Checkbox,
  MenuItem,
  ListSubheader,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";

import LSModal from "./LSModal";
import { GET_LOCATIONS, ADD_COMPANY_USER } from "../../../shared/apiUrls";
import {
  apiGetAuth,
  apiPostAuth,
} from "../../../shared/hoc/pagesWeb/incidentReporter/utils";
import { fontColorLight } from "../../../theme/colors";
import { permissionsList, defaultUserPermissions } from "../../pages/constants";
import { validateEmail } from "../../pages/utils";
import LSButton, { buttonVariants } from "../buttons/LSButton";
import Card, { cardVariant } from "../cards/Card";
import LSDropdownMultiplePicker from "../formInputs/LSDropdownMultiplePicker";
import LSInput, { inputVariants } from "../formInputs/LSInput";
import LSText, { textVariant } from "../text/LSText";

const newUserObject = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  position: "",
  role: "",
};

const userErrorsObject = {
  firstName: false,
  lastName: false,
  email: false,
  phone: false,
  position: false,
  role: false,
};

export default function AddUserModal({
  setUsers,
  addModalVisible,
  setAddModalVisible,
}) {
  const [addUserStep, setAddUserStep] = useState(1);
  const [newUser, setNewUser] = useState(newUserObject);
  const [userErrors, setUserErrors] = useState(userErrorsObject);
  const [locations, setLocations] = useState([]);
  const [answer, setAnswer] = useState({ locationIDs: [] });
  const [permissions, setPermissions] = useState(defaultUserPermissions);

  const handleAddUserNextStep = () => {
    if (!validateLocationLocation()) {
      setAddUserStep((prevState) => prevState + 1);
    }
  };

  const getLocations = async () => {
    const results = await apiGetAuth(GET_LOCATIONS);
    setLocations(results);
  };

  useEffect(() => {
    getLocations();
  }, []);

  const handleAddUserPress = async () => {
    const errors = validateLocationLocation();

    if (errors) return;

    const response = await apiPostAuth(ADD_COMPANY_USER, {
      ...newUser,
      userPermission: permissions,
      userLocations: answer.locationIDs.map((locationId) => ({
        locationUUID: locationId,
      })),
    });
    if (response.firstName) {
      setUsers((prevState) => [response, ...prevState]);
      setNewUser(newUserObject);
      setAddModalVisible(false);
    }
  };

  const validateLocationLocation = () => {
    let errors = false;

    const keys = Object.keys(newUser);
    keys.forEach((key) => {
      if (!newUser[key]) {
        setUserErrors((prevState) => ({
          ...prevState,
          [key]: true,
        }));
        errors = true;
      } else {
        setUserErrors((prevState) => ({
          ...prevState,
          [key]: false,
        }));
      }
    });
    const validEmail = validateEmail(newUser.email);
    if (!validEmail) {
      setUserErrors((prevState) => ({
        ...prevState,
        email: true,
      }));
      errors = true;
    } else {
      setUserErrors((prevState) => ({
        ...prevState,
        email: false,
      }));
    }

    return errors;
  };

  const handleSetUserPermissions = (name) => {
    setPermissions((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const getAddUserFormContent = () => {
    if (addUserStep === 1) {
      return (
        <>
          <LSInput
            variant={inputVariants.primary}
            setter={setNewUser}
            object={newUser}
            name="firstName"
            placeholder="First Name"
            errorObj={userErrors}
            customStyles={styles.inputStyles}
          />
          <LSInput
            variant={inputVariants.primary}
            setter={setNewUser}
            object={newUser}
            name="lastName"
            placeholder="Last Name"
            errorObj={userErrors}
            customStyles={styles.inputStyles}
          />
          <LSInput
            variant={inputVariants.primary}
            setter={setNewUser}
            object={newUser}
            name="email"
            placeholder="Email"
            errorObj={userErrors}
            customStyles={styles.inputStyles}
          />
          <LSInput
            variant={inputVariants.primary}
            setter={setNewUser}
            object={newUser}
            name="phone"
            placeholder="Phone"
            errorObj={userErrors}
            customStyles={styles.inputStyles}
          />
          <LSInput
            variant={inputVariants.primary}
            setter={setNewUser}
            object={newUser}
            name="position"
            placeholder="Position"
            errorObj={userErrors}
            customStyles={styles.inputStyles}
          />
          <LSInput
            variant={inputVariants.primary}
            setter={setNewUser}
            object={newUser}
            name="role"
            placeholder="Role"
            errorObj={userErrors}
            customStyles={styles.inputStyles}
          />
        </>
      );
    }
    if (addUserStep === 2) {
      const mappedQuestionItems = locations?.map((option) => ({
        key: option.uuid,
        value: option.name,
      }));

      return (
        <LSDropdownMultiplePicker
          _items={mappedQuestionItems}
          answer={answer}
          name="locationIDs"
          setValue={setAnswer}
          answerError={false}
          allOption
        />
      );
    }
    if (addUserStep === 3) {
      return (
        <Box>
          <Typography variant="h6">Permissions</Typography>
          <Box sx={{ height: "340px", overflow: "scroll" }}>
            {permissionsList?.map((permissionGroup, _idx) => (
              <>
                <ListSubheader
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${permissionGroup.category}-${_idx}`}
                  sx={{
                    color: "black",
                    fontWeight: "bold",
                    borderBottom: 1,
                  }}
                >
                  {permissionGroup.category}
                </ListSubheader>
                {permissionGroup.permissions.map((permission, idx) => (
                  <MenuItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${permission.name}-idx-${idx}`}
                    sx={{ marginLeft: -3 }}
                    onClick={() => handleSetUserPermissions(permission.name)}
                  >
                    <Checkbox checked={permissions?.[permission.name]} />
                    <ListItemText primary={`${permission.permissionName}`} />
                  </MenuItem>
                ))}
              </>
            ))}
          </Box>
        </Box>
      );
    }
    return "";
  };
  return (
    <LSModal visible={addModalVisible} setVisible={setAddModalVisible}>
      <Card
        variant={cardVariant.primary}
        customStyles={{ width: 310, maxWidth: "100%" }}
      >
        <View style={{ width: "100%", marginBottom: 32 }}>
          <LSText
            text="Add New User"
            variant={textVariant.h2}
            customStyles={{ marginBottom: 16, textAlign: "center" }}
          />

          {getAddUserFormContent()}
        </View>
        <View style={styles.buttonsContainer}>
          <LSButton
            onPress={() =>
              addUserStep === 1
                ? setAddModalVisible(false)
                : setAddUserStep((prevState) => prevState - 1)
            }
            text={addUserStep === 1 ? "Cancel" : "Back"}
            variant={buttonVariants.secondary}
            customStyles={{ marginRight: 16, flex: 1 }}
          />
          <LSButton
            onPress={
              addUserStep < 3 ? handleAddUserNextStep : handleAddUserPress
            }
            variant={buttonVariants.primary}
            text="Next"
            customStyles={{ flex: 1 }}
          />
        </View>
      </Card>
    </LSModal>
  );
}

const styles = StyleSheet.create({
  spinnerContainerStyles: {
    height: "80vh",
    justifyContent: "center",
  },
  headerContainerStyles: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
  buttonsContainer: {
    flexDirection: "row",
    width: "100%",
  },
  inputStyles: { margin: 0, marginBottom: 8 },
  submissionContainer: {
    borderBottomColor: fontColorLight,
    borderBottomWidth: 1,
    flexDirection: "row",
    paddingTop: 16,
    paddingBottom: 8,
  },
  submissionContainerLeft: {
    flex: 1,
  },
});

AddUserModal.propTypes = {
  addModalVisible: PropTypes.bool.isRequired,
  setAddModalVisible: PropTypes.func.isRequired,
  setUsers: PropTypes.func.isRequired,
};
