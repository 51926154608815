import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { useSearchParams } from "react-router-dom";

import { apiGetForm } from "../../../shared/hoc/pagesWeb/incidentReporter/utils";
import { fontColorLight } from "../../../theme/colors";
import PageLayout from "../../components/layouts/PageLayout";
import LSSpinner from "../../components/spinner/LSSpinner";
import LSText, { textVariant } from "../../components/text/LSText";
import { pageAppTypes } from "../constants";

const pageTitle = "Report Setup";

export default function IncidentReportsEditing() {
  const [searchParams] = useSearchParams();
  const formID = searchParams.get("form");
  const [loading, setLoading] = useState(true);
  const [form, setForm] = useState({});

  const getForm = async () => {
    setLoading(true);
    const response = await apiGetForm(formID);
    setForm(response);
    setLoading(false);
  };

  useEffect(() => {
    getForm();
  }, []);

  return (
    <PageLayout pageAppType={pageAppTypes.incidentReports}>
      <LSText
        variant={textVariant.h1}
        text={pageTitle}
        color={fontColorLight}
      />
      {loading ? (
        <View style={styles.spinnerContainerStyles}>
          <LSSpinner />
        </View>
      ) : (
        <View>
          <>
            <LSText
              variant={textVariant.h3}
              text={form.name}
              color={fontColorLight}
            />
            <LSText
              variant={textVariant.h3}
              text={form.name}
              color={fontColorLight}
            />
            <div />
          </>
        </View>
      )}
    </PageLayout>
  );
}

const styles = StyleSheet.create({
  spinnerContainerStyles: {
    height: "80vh",
    justifyContent: "center",
  },
});
