import { useState, useEffect } from "react";

import { COMPANY_BASE_URL } from "../../shared/apiUrls";
import {
  apiGetAuth,
  apiPatchAuth,
} from "../../shared/hoc/pagesWeb/incidentReporter/utils";

export default function useCompany() {
  const [company, setCompany] = useState();
  const [loadingCompany, setLoadingCompany] = useState(false);

  const getCompany = async () => {
    try {
      setLoadingCompany(true);
      const response = await apiGetAuth(COMPANY_BASE_URL);
      setCompany(response);
      setLoadingCompany(false);
    } catch (e) {
      console.error(e);
    }
  };

  const handleCompanyChange = (e) => {
    const { name, value } = e.target;
    console.log({ name, value });
    if (name === "logo") {
      const file = e.target.files[0];
      console.log({ file });
      setCompany((prevState) => ({
        ...prevState,
        [name]: { _formFile: file, newFile: true },
      }));
    } else {
      setCompany((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSaveChanges = async () => {
    console.log("Saving Changes");
    const formData = new FormData();
    formData.append("name", company.name);

    const reader = new FileReader();

    // eslint-disable-next-line no-await-in-loop
    if (company?.logo?.newFile) {
      const fileContent = await new Promise((resolve, reject) => {
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(company.logo._formFile);
      });

      formData.append("base64LogoFile", fileContent);
    }

    const response = await apiPatchAuth(COMPANY_BASE_URL, formData);
    console.log({ response });
  };

  useEffect(() => {
    console.log(company);
  }, [company]);

  useEffect(() => {
    getCompany();
  }, []);

  return { company, loadingCompany, handleCompanyChange, handleSaveChanges };
}
