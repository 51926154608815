import PropTypes from "prop-types";
import React from "react";
import { StyleSheet, TextInput, View } from "react-native";

import { fontColorDark, neutral } from "../../../theme/colors";

export default function LSInput({
  variant,
  placeholder,
  name,
  object,
  errorObj,
  errorArr,
  setter,
  password,
  customStyles,
  selfSet,
  index,
  rightIconElement,
  multiline,
  numberOfLines,
}) {
  const handleOnTextChange = (e) => {
    setter((prevState) => ({
      ...prevState,
      [name]: e,
    }));
  };

  const baseStyels =
    errorObj[name] || errorArr[index] ? style.error[variant] : style[variant];

  return (
    <View style={style.containerStyles}>
      <TextInput
        multiline={multiline}
        numberOfLines={numberOfLines}
        style={[baseStyels, customStyles]}
        value={object[name]}
        placeholder={placeholder}
        onChangeText={
          selfSet ? handleOnTextChange : (e) => setter(e, name, index)
        }
        secureTextEntry={password}
      />
      {rightIconElement}
    </View>
  );
}

const defaultStyles = {
  height: 40,
  marginTop: 12,
  borderWidth: 1,
  borderRadius: 8,
  padding: 10,
  flex: 1,
};

const style = StyleSheet.create({
  primary: {
    ...defaultStyles,
    backgroundColor: neutral[100],
  },
  underline: {
    ...defaultStyles,
    maxHeight: 40,
    borderColor: "transparent",
    borderRadius: 0,
    borderWidth: 1,
    fontSize: 16,
    paddingLeft: 0,
    borderBottomColor: fontColorDark,
    placeholderTextColor: fontColorDark,
    color: fontColorDark,
    outlineStyle: "none",
  },
  error: {
    primary: {
      ...defaultStyles,
      borderColor: "red",
    },
    underline: {
      ...defaultStyles,
      maxHeight: 40,
      borderColor: "transparent",
      borderRadius: 0,
      borderWidth: 1,
      fontSize: 16,
      paddingLeft: 0,
      borderBottomColor: "red",
      placeholderTextColor: fontColorDark,
      color: fontColorDark,
      outlineStyle: "none",
    },
  },
  containerStyles: {
    position: "relative",
  },
});

export const inputVariants = {
  primary: "primary",
  underline: "underline",
  error: "error",
};

LSInput.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  object: PropTypes.object.isRequired,
  errorObj: PropTypes.object,
  errorArr: PropTypes.array,
  setter: PropTypes.func.isRequired,
  rightIconElement: PropTypes.object,
  variant: PropTypes.string,
  password: PropTypes.bool,
  customStyles: PropTypes.object,
  selfSet: PropTypes.bool,
  index: PropTypes.number,
  multiline: PropTypes.bool,
  numberOfLines: PropTypes.number,
};

LSInput.defaultProps = {
  placeholder: "",
  rightIconElement: undefined,
  variant: inputVariants.primary,
  errorObj: {},
  errorArr: [],
  password: false,
  customStyles: {},
  selfSet: true,
  index: 0,
  multiline: false,
  numberOfLines: 6,
};
