import { green, yellow } from "../../../theme/colors";

export const incidentReportSubmitStyles = {
  cardStyles: {
    width: "400px",
    borderRadius: 8,
    padding: "16px",
  },
  cardBodyStyles: {
    display: "flex",
    flexDirection: "column",
  },
  cardHeaderTestStyles: {
    marginTop: "16px",
    marginBottom: "16px",
  },
  cardFooterStyles: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    height: 80,
    marginTop: "16px",
  },
  backButtonStyles: {
    borderColor: yellow[400],
    color: yellow[400],
    width: "100px",
    height: "40px",
  },
  nextButtonStyles: {
    backgroundColor: green[400],
    color: "white",
    flex: 1,
    marginLeft: "16px",
    width: "100px",
    height: "40px",
  },
};

export const hello = "hello";
