import { NavigationContainer } from "@react-navigation/native";
import { useFonts } from "expo-font";
import React from "react";
import { AppState } from "react-native";
import { Provider } from "react-redux";

import RobotoSerif from "./assets/fonts/RobotoSerif.ttf";
import { AuthProvider } from "./context/AuthContext";
import MainIOSNavigator from "./navigators/MainIOSNavigator";
import MainWebNavigator from "./navigators/MainWebNavigator";
import store from "./src/redux/store";
import { isWeb } from "./utils/environments";

function App() {
  const [fontsLoaded] = useFonts({
    "Roboto-Serif": RobotoSerif,
  });

  if (!fontsLoaded) {
    return null;
  }

  if (AppState.currentState !== "active") {
    return null;
  }

  return (
    <Provider store={store}>
      <AuthProvider>
        {isWeb ? (
          <MainWebNavigator />
        ) : (
          <NavigationContainer>
            <MainIOSNavigator />
          </NavigationContainer>
        )}
      </AuthProvider>
    </Provider>
  );
}

export default App;
