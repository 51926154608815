import { useState, useEffect } from "react";

import {
  GET_USER_PERMISSIONS,
  UPDATE_USER_PERMISSIONS,
} from "../../shared/apiUrls";
import {
  apiGetAuth,
  apiPatchAuth,
} from "../../shared/hoc/pagesWeb/incidentReporter/utils";
import { defaultUserPermissions } from "../pages/constants";

export default function useUserPermissions(user) {
  const [userPermissions, setUserPermissions] = useState(
    defaultUserPermissions
  );

  const handleGetUserPermissions = async () => {
    if (user?.uuid) {
      const response = await apiGetAuth(`${GET_USER_PERMISSIONS}/${user.uuid}`);
      setUserPermissions(response);
    }
  };

  useEffect(() => {
    handleGetUserPermissions();
  }, [user]);

  const handleUpdatePermissions = (permissionName) => {
    setUserPermissions((prevState) => ({
      ...prevState,
      [permissionName]: !prevState[permissionName],
    }));
  };

  const handleSave = async () => {
    await apiPatchAuth(`${UPDATE_USER_PERMISSIONS}`, userPermissions);
  };

  return { userPermissions, handleUpdatePermissions, handleSave };
}
