import PropTypes from "prop-types";
import React from "react";
import { View } from "react-native";

import { green, neutral } from "../../../theme/colors";

export default function ProgressBar({ count, total }) {
  return (
    <View
      style={{
        width: "100%",
        height: 8,
        backgroundColor: neutral[300],
        borderRadius: 4,
      }}
    >
      <View
        style={{
          width: `${((count + 1) / total) * 100}%`,
          height: 8,
          backgroundColor: green[400],
          borderRadius: 4,
        }}
      />
    </View>
  );
}

ProgressBar.propTypes = {
  count: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

ProgressBar.defaultProps = {};
