import React from "react";

import PageLayout from "../../components/layouts/PageLayout";
import { pageAppTypes } from "../constants";

function PhotoGallery() {
  return (
    <PageLayout pageAppType={pageAppTypes.locations}>
      <div>
        <h1>Photo Gallery Coming Soon</h1>
      </div>
    </PageLayout>
  );
}

export default PhotoGallery;
