import Notifications from "@mui/icons-material/NotificationsNone";
import { Paper } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Pressable, View } from "react-native";

import { fontColorDark } from "../../../theme/colors";
import LSText, { textVariant } from "../text/LSText";

export default function MappableCard({
  handleOnPress,
  questionAnswer,
  idx,
  setDragItemIndex,
  setDragOverItemIndex,
  handleDrop,
  cardStyles,
  idxObject,
  collapse,
  handleContextMenuClick,
  objectIds,
}) {
  const { text, uuid, typeCode, qaUserNotifications } = questionAnswer;
  const [shouldCollapse, setShouldCollapse] = useState(collapse);

  const onDragStart = () => {
    setDragItemIndex(idxObject);
  };

  const onDragEnter = () => {
    setDragOverItemIndex(idxObject);
  };

  const onDragEnd = () => {
    setDragOverItemIndex(undefined);
    setDragItemIndex(undefined);
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    setShouldCollapse(collapse);
  }, [collapse]);

  return (
    !shouldCollapse && (
      <div
        style={{ width: "100%" }}
        draggable
        onDragStart={() => onDragStart()}
        onDragEnd={onDragEnd}
        onDragEnter={onDragEnter}
        onDragOver={onDragOver}
        onDrop={() => handleDrop(idxObject)}
        onContextMenu={(e) =>
          handleContextMenuClick(
            e,
            objectIds
              ? {
                  ...questionAnswer,
                  answerOptionUUID: objectIds.answerID,
                  idxObject,
                }
              : { ...questionAnswer, idxObject }
          )
        }
      >
        <Pressable onPress={() => handleOnPress(uuid, idx)}>
          <Paper elevation={2} sx={{ ...cardStyles }}>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                flex: 1,
                width: "100%",
              }}
            >
              <LSText
                variant={textVariant.h6}
                text={`${typeCode ? "Q" : "A"}${idx + 1} | ${text}`}
                color={fontColorDark}
              />
              <View
                style={{
                  flexDirection: "row",
                }}
              >
                {qaUserNotifications?.length > 0 ? (
                  <Notifications
                    sx={{
                      color: "#ba000d",
                      marginTop: "-4px",
                      marginBottom: -1,
                    }}
                  />
                ) : (
                  ""
                )}
              </View>
            </View>
          </Paper>
        </Pressable>
      </div>
    )
  );
}

MappableCard.propTypes = {
  handleOnPress: PropTypes.func.isRequired,
  questionAnswer: PropTypes.object.isRequired,
  idx: PropTypes.number.isRequired,
  setDragItemIndex: PropTypes.func.isRequired,
  setDragOverItemIndex: PropTypes.func.isRequired,
  handleDrop: PropTypes.func.isRequired,
  handleContextMenuClick: PropTypes.func.isRequired,
  cardStyles: PropTypes.object.isRequired,
  idxObject: PropTypes.object.isRequired,
  objectIds: PropTypes.object,
  collapse: PropTypes.bool,
};

MappableCard.defaultProps = {
  collapse: false,
  objectIds: null,
};
