export const companySizeOptions = [
  {
    label: "1 - 10",
    value: "10",
  },
  {
    label: "11 - 50",
    value: "50",
  },
  {
    label: "51 - 100",
    value: "100",
  },
  {
    label: "100+",
    value: "100+",
  },
];

export const emptyRegistrationObject = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  password: "",
  passwordConfirm: "",
  companyName: "",
  position: "",
  companySize: "",
};

export const registrationErrorsObject = {
  firstName: null,
  lastName: null,
  email: null,
  phone: null,
  password: null,
  passwordConfirm: null,
  position: null,
  companyName: null,
  companySize: null,
};

export const registrationErrorMessages = {
  firstName: "Please enter your first name",
  lastName: "Please enter your last name",
  email: "Please enter a valid email",
  phone: "Please enter a valid phone number",
  password: "Please enter an 8 character password",
  passwordConfirm: "Passwords don't match",
  position: "Please indicate your position in the company",
  companyName: "Please enter your company's name",
  companySize: "Please indicate the size of your company",
};

export const registrationFormPlaceholders = {
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email Address",
  phone: "Phone Number",
  password: "Password",
  passwordConfirm: "Confirm Password",
  companyName: "Company Name",
  position: "Your Position",
  companySize: "Company Size",
};

export const hasNumberRegex = /\d/;

export const isEmailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w+)+$/;
