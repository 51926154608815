import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";

import FileViewer from "./FileViewer";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function InputFileUpload({
  handleUploadFile,
  answer,
  name,
  errors,
}) {
  const handleFileChange = (e) => {
    handleUploadFile(e.target.files);
  };

  const displayFiles = () => {
    const fileDisplay = [];
    for (let i = 0; i < answer?.files?.length; i += 1) {
      fileDisplay.push(<FileViewer file={answer.files[i]} />);
    }
    return fileDisplay;
  };

  return (
    <>
      <Button
        component="label"
        role={undefined}
        variant={errors ? "outlined" : "contained"}
        color={errors ? "error" : "primary"}
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}
      >
        Upload file
        <VisuallyHiddenInput type="file" multiple onChange={handleFileChange} />
      </Button>
      <Box sx={{ marginTop: "12px" }}>{displayFiles()}</Box>
    </>
  );
}

InputFileUpload.propTypes = {
  handleUploadFile: PropTypes.func.isRequired,
  answer: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  errors: PropTypes.bool,
};

InputFileUpload.defaultProps = {
  errors: false,
};
