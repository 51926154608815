import { neutral } from "../../../../theme/colors";
import pageRoutes from "../../constants/pageRoutes";

export const fontColor = neutral[100];
export const fontColorDark = neutral[400];

export const incidentTabOptions = [
  {
    text: "Home",
    icon: "home",
    path: pageRoutes.home,
  },
  {
    text: "Dashboard",
    icon: "tachometer",
    path: pageRoutes.incidentsDashboard,
  },
  {
    text: "Forms",
    icon: "file-text-o",
    path: pageRoutes.incidentsForms,
  },
  {
    text: "Reports",
    icon: "files-o",
    path: pageRoutes.incidentsReports,
  },
  {
    text: "Settings",
    icon: "gear",
    path: pageRoutes.home,
  },
];

export const emptyForm = {
  name: "",
  template: "",
};

export const questionTypeCodes = {
  dropdown: 1,
  text: 2,
  multiple: 3,
  calendar: 4,
  paragraph: 5,
  upload: 6,
  dataSelect: 7,
  formSender: 8,
};

export const canHaveSubAnswers = [
  questionTypeCodes.dropdown,
  questionTypeCodes.multiple,
  questionTypeCodes.dataSelect,
];

export const hasTextAnswer = [
  questionTypeCodes.text,
  questionTypeCodes.paragraph,
];

export const questionTypeOptions = {
  dropdown: "Dropdown Menu",
  text: "Text Box",
  multiple: "Multiple Choice",
  calendar: "Calendar",
  paragraph: "Paragraph",
  upload: "Upload",
  dataSelect: "Data List",
  formSender: "Form Sender",
};

export const questionTypeOptionsShort = {
  1: "dropdown",
  2: "text",
  3: "multiple",
  4: "calendar",
  5: "paragraph",
  6: "upload",
  7: "dataSelect",
  8: "formSender",
};

export const questionTypeHeights = {
  dropdown: {
    baseHeight: 104,
    questionHeight: 0,
  },
  text: {
    baseHeight: 104,
    questionHeight: 0,
  },
  multiple: {
    baseHeight: 0,
    questionHeight: 50,
  },
  calendar: {
    baseHeight: 150,
    questionHeight: 0,
  },
  paragraph: {
    baseHeight: 300,
    questionHeight: 0,
  },
};
