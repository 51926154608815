// eslint-disable-next-line import/no-extraneous-dependencies
import { configureStore } from "@reduxjs/toolkit";

import permissionsReducer from "./permissions";

export default configureStore({
  reducer: {
    permissions: permissionsReducer,
  },
});
