import PropTypes from "prop-types";
import React from "react";
import { StyleSheet, View } from "react-native";

import LSModal from "./LSModal";
import { apiDeleteQuestion } from "../../../shared/components/Sliders/utils";
import { responseCodes } from "../../constants/constants";
import LSButton, { buttonVariants } from "../buttons/LSButton";
import Card, { cardVariant } from "../cards/Card";
import LSText, { textVariant } from "../text/LSText";

export default function DeleteQuestionModal({
  trashModalVisible,
  setTrashModalVisible,
  selectedQuestionAnswer,
  handleDeleteQuestion,
}) {
  const deleteQuestion = async () => {
    const response = await apiDeleteQuestion(
      selectedQuestionAnswer.uuid,
      selectedQuestionAnswer.answerOptionUUID
    );
    if (response.status === responseCodes.OK) {
      setTrashModalVisible(false);
      handleDeleteQuestion(selectedQuestionAnswer.idxObject);
    }
  };

  return (
    <LSModal visible={trashModalVisible} setVisible={setTrashModalVisible}>
      <Card
        variant={cardVariant.primary}
        customStyles={{ width: 310, maxWidth: "100%" }}
      >
        <LSText
          text="Are you sure?"
          variant={textVariant.h2}
          customStyles={{ marginBottom: 16 }}
        />
        <LSText
          text={`Delete: ${selectedQuestionAnswer.text}`}
          variant={textVariant.h4}
        />
        <View style={styles.buttonsContainer}>
          <LSButton
            onPress={() => setTrashModalVisible(false)}
            text="Cancel"
            variant={buttonVariants.secondary}
            customStyles={{ marginRight: 16, flex: 1 }}
          />
          <LSButton
            onPress={deleteQuestion}
            variant={buttonVariants.error}
            text="Delete"
            customStyles={{ flex: 1 }}
          />
        </View>
      </Card>
    </LSModal>
  );
}

DeleteQuestionModal.propTypes = {
  trashModalVisible: PropTypes.bool.isRequired,
  setTrashModalVisible: PropTypes.func.isRequired,
  handleDeleteQuestion: PropTypes.func.isRequired,
  selectedQuestionAnswer: PropTypes.any.isRequired,
};

const styles = StyleSheet.create({
  buttonsContainer: {
    flexDirection: "row",
    width: "100%",
  },
});
