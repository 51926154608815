import PropTypes from "prop-types";
import React from "react";
import { StyleSheet, View } from "react-native";

import { neutral } from "../../../theme/colors";

export default function Card({ children, variant, customStyles }) {
  return <View style={{ ...style[variant], ...customStyles }}>{children}</View>;
}

const style = StyleSheet.create({
  primary: {
    backgroundColor: neutral[50],
    padding: 16,
    borderRadius: 12,
    alignItems: "center",
    justifyContent: "center",
  },
});

export const cardVariant = {
  primary: "primary",
};

Card.propTypes = {
  children: PropTypes.any.isRequired,
  variant: PropTypes.string.isRequired,
  customStyles: PropTypes.object,
};

Card.defaultProps = {
  customStyles: {},
};
