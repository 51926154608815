import { FontAwesome } from "@expo/vector-icons";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { useSearchParams } from "react-router-dom";

import { DOWNLOAD_PDF_REPORT, SUBMITTED_FORM } from "../../../shared/apiUrls";
import { hasTextAnswer } from "../../../shared/hoc/pagesWeb/incidentReporter/constants";
import { apiGetAuth } from "../../../shared/hoc/pagesWeb/incidentReporter/utils";
import { fontColorDark, blue } from "../../../theme/colors";
import PageLayout from "../../components/layouts/PageLayout";
import LSSpinner from "../../components/spinner/LSSpinner";
import LSText, { textVariant } from "../../components/text/LSText";
import { pageAppTypes } from "../constants";
import { getFormattedDate, noAnswerProvidedText } from "../utils";

const pageTitle = "Incident Report";

const imageFileTypes = ["png", "jpeg"];

export default function SubmittedFormView() {
  const [searchParams] = useSearchParams();
  const reportID = searchParams.get("report");
  const [loading, setLoading] = useState(true);
  const [report, setReport] = useState({});

  const getForm = async () => {
    setLoading(true);
    const response = await apiGetAuth(`${SUBMITTED_FORM}/${reportID}`);
    setReport(response);
    setLoading(false);
  };

  const getAnswerTextSimple = (a) => {
    if (hasTextAnswer.includes(a.question.typeCode)) {
      return (
        <LSText
          variant={textVariant.h6}
          text={a.text || noAnswerProvidedText}
          color={fontColorDark}
          customStyles={styles.customTextStyles}
        />
      );
    }
    if (a.answerOptionUUID) {
      return (
        <LSText
          variant={textVariant.h6}
          text={a.answerOption.text}
          color={fontColorDark}
          customStyles={styles.customTextStyles}
        />
      );
    }
    if (a.selectedDate) {
      return (
        <LSText
          variant={textVariant.h6}
          text={getFormattedDate(new Date(a.selectedDate))}
          color={fontColorDark}
          customStyles={styles.customTextStyles}
        />
      );
    }
    if (a.answerOptionAnswers?.length > 0) {
      let answerString = "";
      a.answerOptionAnswers.forEach((aoa, idx) => {
        answerString += `${idx + 1}, ${aoa.answerOption.text}\n`;
      });
      return (
        <LSText
          variant={textVariant.h6}
          text={answerString}
          color={fontColorDark}
          customStyles={styles.customTextStyles}
        />
      );
    }
    if (a.formSendUsers?.length > 0) {
      return a.formSendUsers?.map((fsu) => (
        <LSText
          variant={textVariant.h6}
          text={fsu.email}
          path={
            fsu.formSubmitted
              ? `/submitted-report-view?report=${fsu.formSubmitUUID}`
              : null
          }
          color={fontColorDark}
          customStyles={styles.customTextStyles}
        />
      ));
    }
    return (
      <LSText
        variant={textVariant.h6}
        text={a.savedFiles?.length > 0 ? "" : noAnswerProvidedText}
        color={fontColorDark}
        customStyles={styles.customTextStyles}
      />
    );
  };

  const summaryQuestionAnswer = (a, idx) => (
    <View key={`summary-sub-${a.uuid}`} style={styles.questionBorderStyles}>
      <LSText
        variant={textVariant.h5}
        text={`${idx + 1}: ${a.question.requiresAnswer ? "*" : ""}${
          a.question.text
        }`}
        color={fontColorDark}
        customStyles={{ marginBottom: 8 }}
      />
      {getAnswerTextSimple(a)}
      {a.savedFiles?.length > 0
        ? a.savedFiles.map((file) => {
            const fileType = file.filePath.split(".")[1];
            return imageFileTypes.includes(fileType) ? (
              <a
                key={file.filePath}
                href={`http://localhost:5001${file.filePath}`}
                target="_blank"
                download
                rel="noreferrer"
              >
                <img
                  src={`http://localhost:5001${file.filePath}`}
                  alt="uploaded file"
                  style={{
                    maxHeight: "80px",
                    maxWidth: "80px",
                    borderRadius: "8px",
                    marginTop: "12px",
                  }}
                />
              </a>
            ) : (
              <Box key={file.filePath} sx={{ marginTop: "12px" }}>
                <a
                  href={`http://localhost:5001${file.filePath}`}
                  target="_blank"
                  download
                  rel="noreferrer"
                >
                  <FontAwesome name="file-text-o" size={80} color={blue[700]} />
                </a>
              </Box>
            );
          })
        : ""}
    </View>
  );

  useEffect(() => {
    getForm();
  }, [reportID]);

  return (
    <PageLayout pageAppType={pageAppTypes.incidentReports}>
      <LSText variant={textVariant.h1} text={pageTitle} color={fontColorDark} />
      {loading ? (
        <View style={styles.spinnerContainerStyles}>
          <LSSpinner />
        </View>
      ) : (
        <View style={{ width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <LSText
              variant={textVariant.h3}
              text={report.form.name}
              color={fontColorDark}
            />
            {/* <IconButton onClick={}> */}
            <a href={`${DOWNLOAD_PDF_REPORT}/${reportID}`} download="hello.pdf">
              <PictureAsPdfIcon style={{ color: fontColorDark }} />
            </a>
            {/* </IconButton> */}
          </Box>
          <ScrollView style={{ marginTop: 24 }}>
            {report.answers.map((answer, idx) =>
              summaryQuestionAnswer(answer, idx)
            )}
          </ScrollView>
        </View>
      )}
    </PageLayout>
  );
}

const styles = StyleSheet.create({
  bodyContainerStyles: {
    marginTop: 32,
  },
  questionBorderStyles: {
    borderBottomColor: fontColorDark,
    borderBottomWidth: 1,
    marginBottom: 16,
    paddingBottom: 16,
  },
  customTextStyles: {
    marginBottom: 8,
    marginLeft: 24,
    fontWeight: "normal",
  },
});
