import PropTypes from "prop-types";
import React from "react";

export default function MappableItem({
  children,
  setDragItemIndex,
  setDragOverItemIndex,
  handleDrop,
  idxObject,
}) {
  const onDragStart = () => {
    setDragItemIndex(idxObject);
  };

  const onDragEnter = () => {
    setDragOverItemIndex(idxObject);
  };

  const onDragEnd = () => {
    setDragOverItemIndex(undefined);
    setDragItemIndex(undefined);
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <div
      draggable
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDragEnter={onDragEnter}
      onDragOver={onDragOver}
      onDrop={handleDrop}
    >
      {children}
    </div>
  );
}

MappableItem.propTypes = {
  children: PropTypes.any.isRequired,
  setDragItemIndex: PropTypes.func.isRequired,
  setDragOverItemIndex: PropTypes.func.isRequired,
  handleDrop: PropTypes.func.isRequired,
  idxObject: PropTypes.any.isRequired,
};
