import {
  Menu,
  Dashboard,
  SupervisorAccount,
  FileCopy,
  ExpandMore,
  ExpandLess,
  SettingsApplications,
  GroupAdd,
  Business,
  WarningAmber,
  Report,
  Photo,
  Receipt,
  LocalShipping,
  Inventory,
  Upcoming,
  Person,
} from "@mui/icons-material";
import {
  Button,
  List,
  IconButton,
  Drawer,
  Divider,
  AppBar,
  Box,
  CssBaseline,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Toolbar,
  Typography,
  Collapse,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";

import LSLogo from "../../../assets/LinkStepLogoSmall.svg";
import { useUser } from "../../../context/AuthContext";
import { blue, yellow } from "../../../theme/colors";
import useScreenDimensions from "../../hooks/useScreenDimensions";
import { urlRoutes, defaultUserPermissions } from "../../pages/constants";
import { setPermissions } from "../../redux/permissions";
import ImpersonationModal from "../modals/ImpersonationModal";

const drawerWidth = 240;
const navItems = [
  { name: "Dashboard", path: urlRoutes.home, icon: <Dashboard /> },
  {
    name: "Admin",
    icon: <SupervisorAccount />,
    subItems: [
      {
        name: "Company Profile",
        path: urlRoutes.companySettings,
        icon: <SettingsApplications />,
      },
      {
        name: "Users",
        path: urlRoutes.usersSettings,
        icon: <GroupAdd />,
      },
      {
        name: "Locations",
        path: urlRoutes.locationsSettings,
        icon: <Business />,
      },
    ],
  },
  {
    name: "Incident Reports",
    icon: <WarningAmber />,
    subItems: [
      {
        name: "Form",
        path: urlRoutes.incidentsForms,
        icon: <FileCopy />,
      },
      {
        name: "Submitted Reports",
        path: urlRoutes.incidentsReports,
        icon: <Report />,
      },
    ],
  },
  {
    name: "Coming Soon",
    icon: <Upcoming />,
    subItems: [
      {
        name: "Photo Gallery",
        path: urlRoutes.photoGallery,
        icon: <Photo />,
      },
      {
        name: "Inventory Management",
        path: urlRoutes.inventoryManagement,
        icon: <Inventory />,
      },
      {
        name: "Asset Management",
        path: urlRoutes.assetManagement,
        icon: <LocalShipping />,
      },
      {
        name: "Receipt Tracker",
        path: urlRoutes.receiptTracker,
        icon: <Receipt />,
      },
    ],
  },
];

function PageLayout({ children, pageAppType }) {
  const [viewImpersonation, setViewImpersonation] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const [activeSideMenuItem, setActiveSideMenuItem] = useState(null);
  const { isMobile } = useScreenDimensions();
  const { logout } = useUser();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleMouseEnter = (item) => {
    setActiveMenuItem(item);
  };

  const handleMouseLeave = () => {
    setActiveMenuItem(null);
  };

  const handleSideMenuItemClick = (item) => {
    setActiveSideMenuItem((prevState) => (prevState === item ? null : item));
  };

  const handleLogout = () => {
    console.log("logging out!");
    logout();
    dispatch(setPermissions(defaultUserPermissions));
    navigate("/");
  };

  useEffect(() => {
    navItems.forEach((item) => {
      if (item.path === window?.location.pathname) {
        setActiveSideMenuItem(item);
      }
      item.subItems?.forEach((subItem) => {
        if (subItem.path === window?.location.pathname) {
          setActiveSideMenuItem(subItem);
        }
      });
    });
  }, [window?.location.pathname]);

  const drawer = (
    <Box sx={{ textAlign: "center" }}>
      <Box sx={{ marginY: "24px" }}>
        <img src={LSLogo} style={{ maxHeigh: "100%" }} alt="LinkStep Logo" />
      </Box>
      <Divider />
      <List>
        {navItems.map((item) => {
          const selectedSubItems = item.subItems?.findIndex(
            (_item) => _item.path === window?.location.pathname
          );
          return item.subItems ? (
            <Box key={item.name}>
              <ListItem
                disablePadding
                sx={{
                  backgroundColor:
                    selectedSubItems >= 0 && activeSideMenuItem !== item
                      ? yellow[100]
                      : "inherit",
                }}
              >
                <ListItemButton
                  onClick={() => handleSideMenuItemClick(item)}
                  sx={{
                    backgroundColor:
                      window?.location.pathname === item.path
                        ? yellow[100]
                        : "inherit",
                  }}
                >
                  {item.icon}
                  <ListItemText sx={{ marginLeft: 2 }} primary={item.name} />
                  {activeSideMenuItem === item ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </ListItemButton>
              </ListItem>
              <Collapse in={activeSideMenuItem === item}>
                {item.subItems.map((subItem) => (
                  <ListItemButton
                    onClick={() => handleNavigate(subItem.path)}
                    sx={{
                      marginLeft: 2,
                      backgroundColor:
                        window?.location.pathname === subItem.path
                          ? yellow[100]
                          : "inherit",
                    }}
                  >
                    {subItem.icon}
                    <ListItemText
                      sx={{ marginLeft: 2 }}
                      primary={subItem.name}
                    />
                  </ListItemButton>
                ))}
              </Collapse>
            </Box>
          ) : (
            <ListItem key={item.name} disablePadding>
              <ListItemButton
                onClick={() => handleNavigate(item.path)}
                sx={{
                  backgroundColor:
                    window?.location.pathname === item.path
                      ? yellow[100]
                      : "inherit",
                }}
              >
                {item.icon}
                <ListItemText sx={{ marginLeft: 2 }} primary={item.name} />
              </ListItemButton>
            </ListItem>
          );
        })}
        {isMobile && (
          <ListItem key="logout" disablePadding>
            <ListItemButton onClick={handleLogout}>
              <Person />
              <ListItemText sx={{ marginLeft: 2 }} primary="Logout" />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </Box>
  );

  const sideBar = (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <List>
        {navItems.map((item) =>
          item.subItems ? (
            <Box key={item.name}>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => handleSideMenuItemClick(item)}
                  sx={{
                    backgroundColor:
                      window?.location.pathname === item.path
                        ? yellow[100]
                        : "inherit",
                  }}
                >
                  {item.icon}
                  <ListItemText sx={{ marginLeft: 2 }} primary={item.name} />
                  {activeSideMenuItem === item ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </ListItemButton>
              </ListItem>
              <Collapse in={activeSideMenuItem === item}>
                {item.subItems.map((subItem) => (
                  <ListItemButton
                    onClick={() => handleNavigate(subItem.path)}
                    sx={{
                      marginLeft: 2,
                      backgroundColor:
                        window?.location.pathname === subItem.path
                          ? yellow[100]
                          : "inherit",
                    }}
                  >
                    {subItem.icon}
                    <ListItemText
                      sx={{ marginLeft: 2 }}
                      primary={subItem.name}
                    />
                  </ListItemButton>
                ))}
              </Collapse>
            </Box>
          ) : (
            <ListItem key={item.name} disablePadding>
              <ListItemButton
                onClick={() => handleNavigate(item.path)}
                sx={{
                  backgroundColor:
                    window?.location.pathname === item.path
                      ? yellow[100]
                      : "inherit",
                }}
              >
                {item.icon}
                <ListItemText sx={{ marginLeft: 2 }} primary={item.name} />
              </ListItemButton>
            </ListItem>
          )
        )}
      </List>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          flex: 1,
          justifyContent: "center",
          alignItems: "end",
        }}
      >
        <Button
          variant="outlined"
          sx={{
            marginBottom: "80px",
            marginX: "16px",
            height: "40px",
          }}
          onClick={() => setViewImpersonation(true)}
        >
          Impersonation
        </Button>
      </Box>
    </Box>
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{
          "background-image": `linear-gradient(to right, #852637, ${blue[700]})`,
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <Menu />
          </IconButton>
          <Box
            onClick={() => handleNavigate("/")}
            sx={{ display: "block", cursor: "pointer" }}
          >
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: "block" }}
            >
              LinkStep
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", flex: 1 }} />
          <Box
            sx={{ display: { xs: "none", sm: "flex", flexDirection: "row" } }}
          >
            {navItems.map((item) => (
              <Box
                sx={{ display: "block", position: "relative" }}
                onMouseOver={() => handleMouseEnter(item)}
                onMouseLeave={() => handleMouseLeave(item)}
              >
                <Button
                  key={item.name}
                  onClick={() => handleNavigate(item.path)}
                  sx={{ color: "#fff" }}
                >
                  {item.name}
                </Button>
                {item.subItems && (
                  <Paper
                    elevation={4}
                    sx={{
                      display:
                        activeMenuItem?.name === item.name ? "block" : "none",
                      minWidth: "150px",
                      position: "absolute",
                      padding: 1,
                      backgroundColor: "lightGray",
                    }}
                  >
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      {item.subItems?.map((subItem) => (
                        <Link
                          key={`${subItem.name}-${subItem.path}`}
                          style={{
                            color: "black",
                            textDecoration: "none",
                          }}
                          to={subItem.path}
                        >
                          {subItem.name}
                        </Link>
                      ))}
                    </Box>
                  </Paper>
                )}
              </Box>
            ))}
            <Button
              key="logout-menu"
              onClick={handleLogout}
              sx={{ color: "#fff" }}
            >
              Logout
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant={isMobile ? "temporary" : "permanent"}
          open={isMobile ? mobileOpen : true}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <Toolbar />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            marginTop: "64px",
            marginLeft: pageAppType ? 0 : "-48px",
          }}
        >
          {pageAppType && (
            <Drawer
              container={container}
              variant={isMobile ? "temporary" : "permanent"}
              open={isMobile ? mobileOpen : true}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: "none", sm: "fixed" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                  marginTop: "64px",
                },
              }}
            >
              {sideBar}
            </Drawer>
          )}
          <Paper
            sx={{
              margin: 2,
              padding: 2,
              width: isMobile ? "100%" : "100%",
              marginLeft:
                // eslint-disable-next-line no-nested-ternary
                isMobile && !pageAppType
                  ? 4
                  : isMobile
                  ? -2
                  : `${pageAppType ? drawerWidth - 28 : 16}px`,
            }}
            elevation={3}
          >
            {children}
          </Paper>
        </Box>
      </Box>
      <ImpersonationModal
        visible={viewImpersonation}
        setVisible={setViewImpersonation}
      />
    </Box>
  );
}

PageLayout.propTypes = {
  children: PropTypes.array.isRequired,
  pageAppType: PropTypes.string,
};

PageLayout.defaultProps = {
  pageAppType: null,
};

export default PageLayout;
