import React from "react";

import PageLayout from "../../components/layouts/PageLayout";
import { pageAppTypes } from "../constants";

function InventoryManagement() {
  return (
    <PageLayout pageAppType={pageAppTypes.locations}>
      <div>
        <h1>Inventory Management Coming Soon</h1>
      </div>
    </PageLayout>
  );
}

export default InventoryManagement;
