import PropTypes from "prop-types";
import React, { useState } from "react";

export default function LogoPreviewer({ file }) {
  const [preview, setPreview] = useState();

  const _file = new FileReader();

  _file.onload = () => {
    setPreview(_file.result);
  };

  _file.readAsDataURL(file);

  return (
    <img
      src={preview}
      alt="uploaded file"
      style={{
        maxHeight: "200px",
        maxWidth: "200px",
        borderRadius: "8px",
      }}
    />
  );
}

LogoPreviewer.propTypes = {
  file: PropTypes.object.isRequired,
};
