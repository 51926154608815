import { createStackNavigator } from "@react-navigation/stack";
import React from "react";

import Home from "../src/pages/Home";
import Login from "../src/pages/Login";
import Register from "../src/pages/Register";
import Splash from "../src/pages/Splash";
import { pageNames } from "../src/pages/constants";

// import { useUser } from "../context/AuthContext";

const RootStack = createStackNavigator();

export default function MainIOSNavigator() {
  // const { isLoading, isLoggedIn } = useUser();
  // const initialRouteName = user ? "Tabs" : "Login";

  return (
    <RootStack.Navigator
      initialRouteName={pageNames.login}
      screenOptions={{ headerShown: false }}
    >
      <RootStack.Screen name={pageNames.splash} component={Splash} />
      <RootStack.Screen name={pageNames.home} component={Home} />
      <RootStack.Screen name={pageNames.login} component={Login} />
      <RootStack.Screen name={pageNames.register} component={Register} />
      {/* <RootStack.Screen name="Notifications" component={Notifications} />
      <RootStack.Screen name="Profile" component={Profile} />
      <RootStack.Screen name="Settings" component={Settings} /> */}
    </RootStack.Navigator>
  );
}
