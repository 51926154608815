import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Box, TextField, Button, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

import { BASE_URL } from "../../../shared/apiUrls";
import { fontColorDark } from "../../../theme/colors";
import LogoPreviewer from "../../components/formInputs/LogoPreview";
import PageLayout from "../../components/layouts/PageLayout";
import LSSpinner from "../../components/spinner/LSSpinner";
import LSText, { textVariant } from "../../components/text/LSText";
import useCompany from "../../hooks/useCompany";
import { pageAppTypes } from "../constants";

const pageTitle = "Company Settings";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function CompanySettings() {
  const { loadingCompany, company, handleCompanyChange, handleSaveChanges } =
    useCompany();

  return (
    <PageLayout pageAppType={pageAppTypes.companySettings}>
      <LSText variant={textVariant.h1} text={pageTitle} color={fontColorDark} />
      {loadingCompany ? (
        <LSSpinner />
      ) : (
        <Paper
          elevation={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
            marginX: "24px",
            marginTop: "24px",
            padding: "24px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "24px",
              width: "250px",
            }}
          >
            <Box
              sx={{
                width: "200px",
                height: "200px",
                border: "dashed",
                borderRadius: "16px",
                marginBottom: "16px",
              }}
            >
              {(company?.logo?._formFile && (
                <LogoPreviewer file={company?.logo?._formFile} />
              )) ||
                (company?.logo?.filePath && (
                  <img
                    src={`${BASE_URL}${company?.logo?.filePath}`}
                    alt="uploaded file"
                    style={{
                      maxHeight: "200px",
                      maxWidth: "200px",
                      borderRadius: "8px",
                    }}
                  />
                ))}
            </Box>
            <Button
              component="label"
              role={undefined}
              variant="contained"
              color="primary"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
            >
              Upload file
              <VisuallyHiddenInput
                type="file"
                multiple
                name="logo"
                onChange={handleCompanyChange}
              />
            </Button>
          </Box>
          <Box
            sx={{
              marginLeft: "24px",
              flex: 1,
              marginRight: "24px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "300px",
            }}
          >
            <TextField
              sx={{
                width: "100%",
              }}
              id="outlined-basic"
              label="Company Name"
              defaultValue={company?.name}
              variant="outlined"
              name="name"
              onChange={handleCompanyChange}
            />

            <Box>
              <Button
                sx={{ width: "100%", marginTop: "16px" }}
                variant="contained"
                onClick={handleSaveChanges}
              >
                Save Change
              </Button>
            </Box>
          </Box>
        </Paper>
      )}
    </PageLayout>
  );
}

export default CompanySettings;
