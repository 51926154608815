import PropTypes from "prop-types";
import React, { useState } from "react";
import { View, CheckBox, StyleSheet } from "react-native";

import { neutral } from "../../../theme/colors";
import LSText, { textVariant } from "../text/LSText";

export default function LSDropdownMultiplePicker({
  _items,
  setValue,
  answer,
  name,
  answerError,
  allOption,
}) {
  const [allSelected, setAllSelected] = useState(false);

  const handleSetAllValue = () => {
    if (!allSelected) {
      const allIDs = [];
      _items.forEach((item) => {
        allIDs.push(item.key);
      });
      setValue((prevState) => ({
        ...prevState,
        [name]: allIDs,
      }));
    } else {
      setValue((prevState) => ({
        ...prevState,
        [name]: [],
      }));
    }
    setAllSelected((prevState) => !prevState);
  };

  const handleSetValue = (key) => {
    const included = answer?.[name]?.includes(key);
    if (!included) {
      setValue((prevState) => ({
        ...prevState,
        [name]: [...prevState[name], key],
      }));
    } else {
      const newSelectedArr = answer?.[name]?.filter((id) => key !== id);

      setValue((prevState) => ({
        ...prevState,
        [name]: newSelectedArr,
      }));
    }
  };

  return (
    <View style={styles.container}>
      {allOption && (
        <View key="all-option" style={styles.checkboxContainerStyles}>
          <CheckBox value={allSelected} onValueChange={handleSetAllValue} />
          <LSText
            text="Select All"
            variant={textVariant.h6}
            customStyles={styles.customTextStyles}
          />
        </View>
      )}
      {_items.map((item) => {
        const { key, value } = item;
        const selected = answer?.[name]?.find((id) => id === key) || false;

        return (
          <View key={key} style={styles.checkboxContainerStyles}>
            <CheckBox
              value={selected}
              onValueChange={() => handleSetValue(key)}
            />
            <LSText
              text={value}
              variant={textVariant.text}
              customStyles={styles.customTextStyles}
            />
          </View>
        );
      })}
      {answerError && (
        <LSText
          variant={textVariant.error}
          text={"requires an answer".toUpperCase()}
          color="red"
          customStyles={{ textAlign: "end" }}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 16,
  },
  checkboxContainerStyles: {
    flexDirection: "row",
    marginBottom: 8,
    padding: 4,
    borderBottomColor: neutral[200],
    borderBottomWidth: 1,
  },
  customTextStyles: {
    marginLeft: 8,
  },
});

LSDropdownMultiplePicker.propTypes = {
  _items: PropTypes.array.isRequired,
  setValue: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  answerError: PropTypes.bool.isRequired,
  answer: PropTypes.object.isRequired,
  allOption: PropTypes.bool,
};

LSDropdownMultiplePicker.defaultProps = {
  allOption: false,
};
