import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";

import { localStorageKeys } from "../../../context/constants";
import {
  CREATE_NEW_QUESTION,
  DELETE_QUESTIONS,
  LOCK_MY_FORM,
  MAP_QUESTIONS,
  PUBLISH_MY_FORM,
  UNLOCK_MY_FORM,
  UNPUBLISH_MY_FORM,
  UPDATE_ANSWER_OPTION_QUESTION,
  UPDATE_FORM,
  UPDATE_QUESTION,
} from "../../apiUrls";
import {
  httpErrorHandlerGet,
  httpErrorHandlerPost,
} from "../../hoc/pagesWeb/incidentReporter/utils";

export const validQuestion = (question, setQuestionTextErrors, setQuestion) => {
  let errors = 0;
  if (!question.text) {
    setQuestionTextErrors(true);
    errors += 1;
  } else {
    setQuestionTextErrors(false);
  }
  const answerArr = question.answerOptions;
  question.answerOptions?.forEach((answer, idx) => {
    if (!answer.text) {
      answerArr[idx].error = true;
      errors += 1;
    } else {
      answerArr[idx].error = false;
    }
  });
  setQuestion((prevState) => ({
    ...prevState,
    answerOptions: answerArr,
  }));

  return errors;
};

export const saveQuestion = async (question) => {
  const jwt = await AsyncStorage.getItem(localStorageKeys.accessTokenKey);
  const response = await axios
    .post(CREATE_NEW_QUESTION, question, {
      headers: {
        authorization: `bearer ${jwt}`,
      },
    })
    .catch((e) => httpErrorHandlerPost(e, question));
  return response.data;
};

export const updateQuestion = async (question) => {
  const jwt = await AsyncStorage.getItem(localStorageKeys.accessTokenKey);
  const response = await axios
    .post(UPDATE_QUESTION, question, {
      headers: {
        authorization: `bearer ${jwt}`,
      },
    })
    .catch((e) => httpErrorHandlerPost(e, question));
  return response.data;
};

export const updateAnswerOptionQuestion = async (question, questionsArr) => {
  const jwt = await AsyncStorage.getItem(localStorageKeys.accessTokenKey);
  const response = await axios
    .post(
      UPDATE_ANSWER_OPTION_QUESTION,
      { question, questions: questionsArr },
      {
        headers: {
          authorization: `bearer ${jwt}`,
        },
      }
    )
    .catch((e) => httpErrorHandlerPost(e, question));
  return response;
};

export const handleMapQuestions = async (form) => {
  const jwt = await AsyncStorage.getItem(localStorageKeys.accessTokenKey);
  const response = await axios
    .post(MAP_QUESTIONS, form, {
      headers: {
        authorization: `bearer ${jwt}`,
      },
    })
    .catch(httpErrorHandlerPost);
  return response.data;
};

export const apiDeleteQuestion = async (questionID, answerID) => {
  const jwt = await AsyncStorage.getItem(localStorageKeys.accessTokenKey);
  const response = await axios.delete(
    `${DELETE_QUESTIONS}/${questionID}/${answerID}`,
    {
      headers: {
        authorization: `bearer ${jwt}`,
      },
    }
  );
  return response;
};

export const apiPublishForm = async (id) => {
  const jwt = await AsyncStorage.getItem(localStorageKeys.accessTokenKey);
  const response = await axios
    .get(`${PUBLISH_MY_FORM}/${id}`, {
      headers: {
        authorization: `bearer ${jwt}`,
      },
    })
    .catch(httpErrorHandlerGet);
  return response.data;
};

export const apiUnpublishForm = async (id) => {
  const jwt = await AsyncStorage.getItem(localStorageKeys.accessTokenKey);
  const response = await axios
    .get(`${UNPUBLISH_MY_FORM}/${id}`, {
      headers: {
        authorization: `bearer ${jwt}`,
      },
    })
    .catch(httpErrorHandlerGet);
  return response.data;
};

export const apiUpdateForm = async (form) => {
  const jwt = await AsyncStorage.getItem(localStorageKeys.accessTokenKey);
  const response = await axios
    .put(`${UPDATE_FORM}`, form, {
      headers: {
        authorization: `bearer ${jwt}`,
      },
    })
    .catch(httpErrorHandlerGet);
  return response.data;
};

export const apiLockForm = async (id) => {
  const jwt = await AsyncStorage.getItem(localStorageKeys.accessTokenKey);
  const response = await axios
    .get(`${LOCK_MY_FORM}/${id}`, {
      headers: {
        authorization: `bearer ${jwt}`,
      },
    })
    .catch(httpErrorHandlerGet);
  return response.data;
};

export const apiUnlockForm = async (id) => {
  const jwt = await AsyncStorage.getItem(localStorageKeys.accessTokenKey);
  const response = await axios
    .get(`${UNLOCK_MY_FORM}/${id}`, {
      headers: {
        authorization: `bearer ${jwt}`,
      },
    })
    .catch(httpErrorHandlerGet);
  return response.data;
};

export const setQuestionItemsList = (questionsList) => {
  const mappedQuestionItems = questionsList?.map((question) => ({
    key: question.uuid,
    value: question.text,
  }));
  return mappedQuestionItems;
};
