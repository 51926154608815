import React from "react";

import { fontColorLight } from "../../../theme/colors";
import PageLayout from "../../components/layouts/PageLayout";
import LSText, { textVariant } from "../../components/text/LSText";
import { pageAppTypes } from "../constants";

const pageTitle = "Locations";

export default function LocationsDashboard() {
  return (
    <PageLayout pageAppType={pageAppTypes.locations}>
      <LSText
        variant={textVariant.h1}
        text={pageTitle}
        color={fontColorLight}
      />
    </PageLayout>
  );
}
