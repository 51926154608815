import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import constate from "constate";
import jwtDecode from "jwt-decode";
import { useCallback, useEffect, useState } from "react";
import { AppState } from "react-native";

import { activeAppState, localStorageKeys, millisInSecond } from "./constants";
import {
  LOGIN_USER_URL,
  LOGOUT_USER_URL,
  ME_USER_URL,
  REFRESH_USER_URL,
  REGISTER_COMPANY_URL,
} from "../shared/apiUrls";
import AppEvents from "../utils/eventEmitter";

const headers = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
};

const initialState = {
  user: null,
};

const loginUser = async (userData) => {
  const response = await axios.post(LOGIN_USER_URL, userData, {
    headers,
  });
  return response.data;
};

const _registerCompany = async (companyData) => {
  const response = await axios.post(REGISTER_COMPANY_URL, companyData, {
    withCredentials: true,
  });
  console.log({ response });
  return response.data;
};

const _registerUser = async (userData) => {
  const response = await axios.post(LOGIN_USER_URL, userData, {
    withCredentials: true,
  });
  return response.data;
};

const logoutUser = async (userData) => {
  try {
    const response = await axios.post(LOGOUT_USER_URL, userData);
    return response.data;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const getUser = async () => {
  try {
    const response = await axios.get(ME_USER_URL);
    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const getStoredAccessToken = async () => {
  const _token = await AsyncStorage.getItem(localStorageKeys.accessTokenKey);
  console.log({ _token });
  return _token;
};

const updateAxiosAuthHeader = async (jwt) => {
  console.log("updating", { jwt });
  if (!jwt) {
    delete axios.defaults.headers.common.Authorization;
  } else {
    console.log("setting", { jwt });
    axios.defaults.headers.common.Authorization = `bearer ${jwt}`;
  }
};

const isTokenExpired = (jwt) => {
  if (!jwt) return true;
  const nowInSeconds = Date.now() / millisInSecond;
  const nowTime = Math.floor(nowInSeconds);
  const { exp } = jwtDecode(jwt);
  console.log(exp, nowTime, exp < nowTime);
  return exp < nowTime;
};

export const _renewTokens = async () => {
  console.log("Renewing");
  try {
    const refreshToken = await AsyncStorage.getItem(
      localStorageKeys.refreshToken
    );
    const { data } = await axios.post(REFRESH_USER_URL, {
      refreshToken,
    });
    await AsyncStorage.setItem(
      localStorageKeys.accessTokenKey,
      data.accessToken
    );
    await AsyncStorage.setItem(
      localStorageKeys.refreshToken,
      data.refreshToken
    );
    return data;
  } catch (error) {
    return error;
  }
};

const useAuthContext = () => {
  const [appState, setAppState] = useState(AppState.currentState);
  const [user, setUser] = useState(initialState.user);
  const [token, setToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleSetUser = (_user) => {
    setUser(_user);
  };

  const initializeApp = useCallback(async (newToken) => {
    if (!newToken) {
      return;
    }

    setToken(newToken);

    try {
      const storedUser = await AsyncStorage.getItem(localStorageKeys.user);
      setUser(JSON.parse(storedUser));
      if (!storedUser) {
        const newUser = await getUser();
        setUser(newUser);
      }
      setIsLoggedIn(true);
    } catch (e) {
      setIsLoggedIn(false);
      throw e;
    }
  });

  const getNewTokens = async () => {
    const storedToken = await getStoredAccessToken();
    if (storedToken) {
      const newToken = await _renewTokens();

      if (!newToken || newToken.response?.status) {
        logout();
        return null;
      }

      return newToken;
    }
    return { accessToken: storedToken };
  };

  const handleAppStateChange = useCallback((nextAppState) => {
    setAppState(nextAppState);
  });

  const onUnauthorizedAxiosRequest = useCallback(async () => {
    try {
      const newToken = await getNewTokens();
      if (!newToken) {
        throw new Error("Could not fetch a valid token");
      }
      setToken(newToken);
      updateAxiosAuthHeader(newToken);

      AppEvents.emit("axios:retry", newToken);
    } catch (e) {
      console.warn(e);
      await logout();
      AppEvents.emit("axios:error");
    }
  }, [appState]);

  useEffect(() => {
    AppState.addEventListener("change", handleAppStateChange);
    AppEvents.on("axios:unauthorized", onUnauthorizedAxiosRequest);

    return () => {
      AppState.remove("change", handleAppStateChange);
      AppEvents.off("axios:unauthorized", onUnauthorizedAxiosRequest);
    };
  }, []);

  useEffect(() => {
    (async () => {
      const _token = await AsyncStorage.getItem(
        localStorageKeys.accessTokenKey
      );
      const tokenExpired = isTokenExpired(_token);
      if (appState !== activeAppState || !tokenExpired) {
        return;
      }
      setIsLoading(true);
      try {
        const newTokens = await getNewTokens();
        if (!newTokens) {
          throw new Error("Could not find token for user");
        }
        await updateAxiosAuthHeader(newTokens.accessToken);
        await initializeApp(newTokens);
      } catch (e) {
        console.warn(e);
        await logout();
      } finally {
        setIsLoading(false);
      }
    })();
  }, [appState]);

  const login = useCallback(async (userData) => {
    try {
      const response = await loginUser(userData);
      console.log({ response });
      setUser(response.user);
      await AsyncStorage.setItem(
        localStorageKeys.accessTokenKey,
        response.accessToken
      );
      await AsyncStorage.setItem(
        localStorageKeys.refreshToken,
        response.refreshToken
      );
      await AsyncStorage.setItem(
        localStorageKeys.user,
        JSON.stringify(response.user)
      );
      updateAxiosAuthHeader(response.accessToken);
      setIsLoggedIn(true);
    } catch (err) {
      return err;
    }
    return "Success";
  });

  const registerCompany = useCallback(async (companyData) => {
    try {
      const response = await _registerCompany(companyData);
      return response;
    } catch (err) {
      return err;
    }
  });

  const registerUser = useCallback(async (userData) => {
    try {
      const response = await _registerUser(userData);
      console.log({ user: response });
      // setUser(response.user);
      // await AsyncStorage.setItem(accessTokenKey, response.accessToken);
      // updateAxiosAuthHeader(response.accessToken);
      // setIsLoggedIn(true);
    } catch (err) {
      console.error(err);
      throw err;
    }
  });

  const logout = async () => {
    console.log("logging out");
    setIsLoggedIn(false);
    setUser(null);
    AsyncStorage.removeItem(localStorageKeys.accessTokenKey);
    AsyncStorage.removeItem(localStorageKeys.refreshToken);
    AsyncStorage.removeItem(localStorageKeys.user);
    const response = await logoutUser();
    console.log(response);
  };

  return {
    appState,
    user,
    token,
    isLoggedIn,
    setUser: handleSetUser,
    login,
    registerCompany,
    registerUser,
    logout,
    isLoading,
  };
};

const [AuthProvider, useUser] = constate(useAuthContext);

export { AuthProvider, useUser };
