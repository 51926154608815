import { useEffect, useState } from "react";

import { apiGetFormsSummary } from "../../shared/hoc/pagesWeb/incidentReporter/utils";

function useForms(shouldFetch = true) {
  const [forms, setForms] = useState([]);

  const getFormsDetails = async () => {
    try {
      const response = await apiGetFormsSummary();
      setForms(response);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (shouldFetch) {
      getFormsDetails();
    } else {
      console.log("Not fetching!");
    }
  }, [shouldFetch]);

  return { forms };
}
export default useForms;
