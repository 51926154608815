const incidentsBase = "/incidents";
const userManagementBase = "/user-management";

const pageRoutes = {
  splash: "/",
  login: "/login",
  register: "/register",
  incidentsDashboard: `${incidentsBase}-dashboard`,
  incidentsForms: `${incidentsBase}-forms`,
  incidentsReports: `${incidentsBase}-reports`,
  userManagementDashboard: `${userManagementBase}-dashboard`,
  userManagementUsers: `${userManagementBase}-users`,
  userManagementLocations: `${userManagementBase}-locations`,
  userManagementReports: `${userManagementBase}-reports`,
  home: "/home",
  notFound404: "*",
};

pageRoutes.previewForms = `${pageRoutes.incidentsForms}-preview`;
pageRoutes.questionMap = `${pageRoutes.incidentsForms}-question-map`;

export default pageRoutes;
