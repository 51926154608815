// eslint-disable
import { TextField } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

export default function LSTextField({ errors, answer, handleAnswerChange }) {
  const [textValue, setTextValue] = useState(answer.text || "");

  useEffect(() => {
    setTextValue(answer.text || "");
  }, [answer]);

  return (
    <TextField
      error={errors}
      value={textValue}
      onChange={(e) => handleAnswerChange(e.target.value)}
    />
  );
}

LSTextField.propTypes = {
  answer: PropTypes.object.isRequired,
  handleAnswerChange: PropTypes.func.isRequired,
  errors: PropTypes.bool,
};

LSTextField.defaultProps = {
  errors: false,
};
