import React from "react";
import { StyleSheet } from "react-native";

import PageLayout from "../../components/layouts/PageLayout";
import LSText, { textVariant } from "../../components/text/LSText";
import { pageAppTypes } from "../constants";

const pageTitle = "Incident Reports";

export default function IncidentsDashboard() {
  return (
    <PageLayout pageAppType={pageAppTypes.incidentReports}>
      <LSText variant={textVariant.h1} text={pageTitle} color="white" />
    </PageLayout>
  );
}

const styles = StyleSheet.create({
  bodyContainerStyles: {
    justifyContent: "center",
    flexDirection: "row",
    flexWrap: "wrap",
  },
});
