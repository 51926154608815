import PropTypes from "prop-types";
import React from "react";
import { Button, StyleSheet, View } from "react-native";

import { green, yellow } from "../../../theme/colors";
import { isIOS } from "../../../utils/environments";

export default function LSButton({
  text,
  variant,
  onPress,
  customStyles,
  disabled,
}) {
  return (
    <View style={{ ...containerStyles[variant], ...customStyles }}>
      <Button
        title={text}
        onPress={onPress}
        {...styles[variant]}
        disabled={disabled}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  primary: {
    color: green[400],
    height: "50px",
  },
  secondary: {
    color: yellow[100],
  },
  tertiary: {
    backgroundColor: "white",
  },
  error: {
    backgroundColor: "red",
    color: "red",
  },
});

const buttonMargin = 16;

const containerStyles = StyleSheet.create({
  primary: {
    borderColor: green[400],
    borderWidth: isIOS ? 2 : 0,
    marginTop: buttonMargin,
  },
  secondary: {
    borderColor: yellow[400],
    borderWidth: isIOS ? 2 : 0,
    marginTop: buttonMargin,
  },
  tertiary: {
    borderColor: "white",
    borderWidth: isIOS ? 2 : 0,
    marginTop: buttonMargin,
  },
  error: {
    borderColor: "red",
    borderWidth: isIOS ? 2 : 0,
    marginTop: buttonMargin,
  },
});

export const buttonVariants = {
  primary: "primary",
  secondary: "secondary",
  tertiary: "tertiary",
  error: "error",
};

LSButton.propTypes = {
  text: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
  variant: PropTypes.string,
  customStyles: PropTypes.object,
  disabled: PropTypes.bool,
};

LSButton.defaultProps = {
  variant: buttonVariants.primary,
  customStyles: {},
  disabled: false,
};
