import { FontAwesome, Ionicons, Octicons } from "@expo/vector-icons";
import PropTypes from "prop-types";
import React from "react";
import { Pressable, StyleSheet, View } from "react-native";

import { fontColorLight } from "../../../theme/colors";
import {
  customContextMenuFunctionCalls,
  fontFamilyTypes,
} from "../../pages/constants";
import LSText, { textVariant } from "../text/LSText";

export default function CustomContextMenu({
  showContextMenu,
  buttonObjectArr,
  handleCloseContextMenu,
  handleDuplicateQuestionClick,
  handleDeleteQuestion,
  handleAddNewNextQuestionClick,
  handleEditQuestionClick,
  handleUserNotificationsClick,
}) {
  const handleOptionClick = (functionCall) => {
    console.log(functionCall);
    if (
      functionCall === customContextMenuFunctionCalls.duplicateNextQuestionClick
    ) {
      handleDuplicateQuestionClick();
    } else if (
      functionCall === customContextMenuFunctionCalls.editQuestionClick
    ) {
      handleEditQuestionClick();
    } else if (
      functionCall === customContextMenuFunctionCalls.deleteQuestionClick
    ) {
      handleDeleteQuestion();
    } else if (
      functionCall === customContextMenuFunctionCalls.addNewNextQuestionClick
    ) {
      handleAddNewNextQuestionClick();
    } else if (
      functionCall === customContextMenuFunctionCalls.userNotificationsClick
    ) {
      handleUserNotificationsClick();
    }
  };

  return (
    <>
      <Pressable
        style={{
          ...styles.contextMenuOverlayPressableStyles,
        }}
        onPress={handleCloseContextMenu}
      />
      <View
        style={{
          ...styles.contextMenuContainerStyles,
          top: showContextMenu.cardY,
          left: showContextMenu.cardX,
        }}
      >
        <View style={styles.contextMenuOverlay} />
        <View style={{ width: "100%" }}>
          {buttonObjectArr.map((buttonObject) => (
            <Pressable
              key={buttonObject.text}
              style={{
                ...styles.contextMenuButtonStyles,
                borderBottomColor: fontColorLight,
              }}
              onPress={() => handleOptionClick(buttonObject.functionCall)}
            >
              <View style={{ width: 20 }}>
                {buttonObject.iconFamily === fontFamilyTypes.fontAwesome && (
                  <FontAwesome
                    name={buttonObject.iconName}
                    size={20}
                    color={fontColorLight}
                  />
                )}
                {buttonObject.iconFamily === fontFamilyTypes.ionIcons && (
                  <Ionicons
                    name={buttonObject.iconName}
                    size={20}
                    color={fontColorLight}
                  />
                )}
                {buttonObject.iconFamily === fontFamilyTypes.octIcons && (
                  <Octicons
                    name={buttonObject.iconName}
                    size={20}
                    color={fontColorLight}
                  />
                )}
              </View>
              <LSText
                variant={textVariant.h6}
                text={buttonObject.text}
                color={fontColorLight}
                customStyles={{ marginLeft: 8, marginBottom: 2 }}
              />
            </Pressable>
          ))}
        </View>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  contextMenuButtonStyles: {
    flexDirection: "row",
    alignItems: "center",
    borderBottomWidth: 0.5,
    minWidth: "100%",
    paddingLeft: 8,
    paddingVertical: 8,
  },
  contextMenuOverlay: {
    height: 200,
    width: 300,
    position: "absolute",
    backgroundColor: "#000",
    opacity: 0.9,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: 8,
  },
  contextMenuOverlayPressableStyles: {
    position: "absolute",
    width: "100vw",
    height: "100vh",
    zIndex: 100,
  },
  contextMenuContainerStyles: {
    position: "absolute",
    display: "flex",
    width: 300,
    zIndex: 100,
  },
});

CustomContextMenu.propTypes = {
  showContextMenu: PropTypes.object.isRequired,
  buttonObjectArr: PropTypes.array.isRequired,
  handleCloseContextMenu: PropTypes.func.isRequired,
  handleDuplicateQuestionClick: PropTypes.func.isRequired,
  handleDeleteQuestion: PropTypes.func.isRequired,
  handleAddNewNextQuestionClick: PropTypes.func.isRequired,
  handleEditQuestionClick: PropTypes.func.isRequired,
  handleUserNotificationsClick: PropTypes.func.isRequired,
};
