import {
  FontAwesome,
  Foundation,
  MaterialCommunityIcons,
  MaterialIcons,
} from "@expo/vector-icons";
import PropTypes from "prop-types";
import React from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { useNavigate } from "react-router-dom";

import { fontColorDark, neutral } from "../../../theme/colors";
import { isWeb } from "../../../utils/environments";
import { fontFamilyTypes } from "../../pages/constants";
import LSText, { textVariant } from "../text/LSText";

export default function HomeAppCard({ app }) {
  const navigate = useNavigate();

  const handleAppPress = () => {
    if (isWeb) {
      navigate(app.baseRoute);
    } else {
      console.log("Set up routing for mobile");
      //   navigation.navigate("home");
    }
  };
  return (
    <Pressable onPress={handleAppPress}>
      <View key={app.title} style={styles.appContainer}>
        <LSText
          variant={textVariant.h3}
          color={fontColorDark}
          text={app.title}
          customStyles={{ textAlign: "center" }}
        />
        {app.iconFamily === fontFamilyTypes.fontAwesome && (
          <FontAwesome name={app.iconName} size={50} color={fontColorDark} />
        )}
        {app.iconFamily === fontFamilyTypes.foundation && (
          <Foundation name={app.iconName} size={50} color={fontColorDark} />
        )}
        {app.iconFamily === fontFamilyTypes.materialCommunityIcons && (
          <MaterialCommunityIcons
            name={app.iconName}
            size={50}
            color={fontColorDark}
          />
        )}
        {app.iconFamily === fontFamilyTypes.materialIcons && (
          <MaterialIcons name={app.iconName} size={50} color={fontColorDark} />
        )}
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  appContainer: {
    width: 150,
    height: 150,
    margin: 8,
    borderRadius: 16,
    padding: 16,
    justifyContent: "space-between",
    alignItems: "center",
    borderColor: neutral[200],
    backgroundColor: neutral[100],
    borderWidth: 2,
    shadowColor: neutral[100],
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
  },
});

HomeAppCard.propTypes = {
  app: PropTypes.object.isRequired,
};
