import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, TextField, IconButton, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import QRCode from "react-qr-code";
import { Link } from "react-router-dom";
// eslint-disable-next-line import/no-extraneous-dependencies

import { validateEmail } from "../../utils/utils";

export default function FormSender({
  question,
  answer,
  handleAnswerChange,
  errors,
}) {
  const [emailAddress, setEmailAddress] = useState("");
  console.log({ question, answer });

  const handleEmailChange = (e) => {
    setEmailAddress(e.target.value);
  };

  const handleAddRecipient = () => {
    if (validateEmail(emailAddress)) {
      handleAnswerChange(emailAddress);
      setEmailAddress("");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        minHeight: "350px",
      }}
    >
      <Box sx={{ width: "100%" }}>
        <TextField
          error={errors}
          sx={{ width: "100%" }}
          placeholder="Enter an emails"
          value={emailAddress}
          onChange={handleEmailChange}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
            justifyContent: "center",
          }}
        >
          <IconButton
            color="primary"
            aria-label="add recipients"
            onClick={handleAddRecipient}
          >
            <AddCircleIcon />
          </IconButton>
        </Box>
        {answer.witnesses?.map((witnessEmail, idx) => (
          <Typography level="h1" key={witnessEmail}>
            <b>{idx + 1}:</b> {witnessEmail}
          </Typography>
        ))}
      </Box>
      <Box
        sx={{
          height: "200px",
          width: "200px",
          marginTop: "24px",
        }}
      >
        <Link
          to={`${window.location.origin}/form?form=${question.sendFormUUID}&answerSubmissionUUID=${question.submissionUUID}`}
          target="_blank"
        >
          <QRCode
            value={`${window.location.origin}/form?form=${question.sendFormUUID}&answerSubmissionUUID=${question.submissionUUID}`}
            size={200}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            viewBox="0 0 256 256"
          />
        </Link>
      </Box>
    </Box>
  );
}

FormSender.propTypes = {
  question: PropTypes.object.isRequired,
  answer: PropTypes.object.isRequired,
  handleAnswerChange: PropTypes.func.isRequired,
  errors: PropTypes.bool,
};

FormSender.defaultProps = {
  errors: false,
};
