export const neutral = {
  50: "#FFFFFF",
  75: "#F8F8F8",
  100: "#F0F0F0",
  200: "#CCCCCC",
  300: "#999999",
  400: "#666666",
  500: "#333333",
  550: "#1A1A1A",
  600: "#000000",
};

export const red = {
  50: "#B82647",
  300: "#9C2641",
  600: "#782437",
};

export const blue = {
  200: "#2E3793",
  500: "#161E69",
  700: "#0B0F36",
};

export const yellow = {
  100: "#FFD79B",
  400: "#F3AE48",
};

export const green = {
  100: "#6BDDBA",
  400: "#48B594",
};

export const colors = {
  error: "red.500",
};

export const fontColorLight = "white";
export const fontColorDark = "black";
export const cardBackgroundColor = neutral[100];
