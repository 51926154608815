import { ExpandMore, ExpandLess } from "@mui/icons-material";
import {
  Box,
  Typography,
  IconButton,
  Collapse,
  ListItemText,
  Checkbox,
  MenuItem,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { apiUpdateForm } from "../../../shared/components/Sliders/utils";
import LSButton, { buttonVariants } from "../buttons/LSButton";

const accessTypes = [
  { key: 1, value: "Public" },
  { key: 2, value: "Internal" },
  { key: 3, value: "Location Based" },
];

function FormSettingsModalContent({
  form,
  users,
  closeModal,
  handleUpdateFormSettings,
}) {
  const [showSetting, setShowSetting] = useState(0);
  const [formSettings, setFormSettings] = useState({
    admins:
      form.formAdmins?.map((admin) => ({ key: admin.userUUID, value: "" })) ||
      [],
    access: form.accessType,
  });

  const handleOpenSettings = (num) => {
    if (num === showSetting) {
      setShowSetting(0);
    } else {
      setShowSetting(num);
    }
  };

  const handleChangeAccess = (type) => {
    setFormSettings((prevState) => ({
      ...prevState,
      access: type,
    }));
  };

  const handleChangeAdmins = (user) => {
    const adminIdx = formSettings.admins.findIndex((x) => x.key === user.uuid);
    if (adminIdx > -1) {
      // remove the user
      const tempAdminsArr = formSettings.admins;
      tempAdminsArr.splice(adminIdx, 1);
      setFormSettings((prevState) => ({
        ...prevState,
        admins: tempAdminsArr,
      }));
    } else {
      setFormSettings((prevState) => ({
        ...prevState,
        admins: [
          ...prevState.admins,
          {
            key: user.uuid,
            value: `${user.firstName} ${user.lastName}`,
          },
        ],
      }));
    }
  };

  const handleUpdateSettings = async () => {
    const body = {
      uuid: form.uuid,
      formAdmins: formSettings.admins.map((admin) => ({
        formUUID: form.uuid,
        userUUID: admin.key,
      })),
      accessType: formSettings.access,
    };
    console.log({ formSettings, body });
    const response = await apiUpdateForm(body);
    console.log({ response });
    handleUpdateFormSettings(response);
    closeModal();
  };

  return (
    <Box sx={{ width: "100%", marginBottom: "32px" }}>
      <Box sx={{ borderBottom: 1 }}>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          Form Settings
        </Typography>
        <Typography variant="overline" sx={{ fontWeight: 600 }}>
          {form.name}
        </Typography>
      </Box>
      <Box
        sx={{
          borderBottom: 1,
          borderBottomColor: "lightGray",
          marginY: 0.5,
          paddingY: 0.5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="body1"
            sx={{ fontWeight: 500, marginTop: "8px" }}
          >
            Admins
          </Typography>
          <IconButton onClick={() => handleOpenSettings(1)}>
            {showSetting === 1 ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>
        <Collapse in={showSetting === 1}>
          <Typography
            variant="body2"
            sx={{ fontWeight: 500, marginTop: "8px", marginBottom: "16px" }}
          >
            Admins can edit, (un)publish, lock and open access to the given
            form.
          </Typography>
          <Box sx={{ height: "200px", overflow: "scroll" }}>
            {users.map((user) => (
              <MenuItem
                key={user.uuid}
                value={user}
                sx={{ marginLeft: -3 }}
                onClick={() => handleChangeAdmins(user)}
              >
                <Checkbox
                  checked={
                    formSettings.admins.findIndex((x) => x.key === user.uuid) >
                    -1
                  }
                />
                <ListItemText primary={`${user.firstName} ${user.lastName}`} />
              </MenuItem>
            ))}
          </Box>
        </Collapse>
      </Box>
      <Box
        sx={{
          borderBottom: 1,
          borderBottomColor: "lightGray",
          marginY: 0.5,
          paddingY: 0.5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="body1"
            sx={{ fontWeight: 500, marginTop: "8px" }}
          >
            Access
          </Typography>
          <IconButton onClick={() => handleOpenSettings(2)}>
            {showSetting === 2 ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>
        <Collapse in={showSetting === 2}>
          <Box sx={{ height: "200px", overflow: "scroll" }}>
            {accessTypes.map((accessType) => (
              <MenuItem
                key={accessType.key}
                value={accessType}
                sx={{ marginLeft: -3 }}
                onClick={() => handleChangeAccess(accessType.key)}
              >
                <Checkbox checked={formSettings.access === accessType.key} />
                <ListItemText primary={`${accessType.value}`} />
              </MenuItem>
            ))}
          </Box>
        </Collapse>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <LSButton
          text="Cancel"
          variant={buttonVariants.secondary}
          customStyles={{}}
          onPress={closeModal}
        />
        <LSButton
          text="Save Changes"
          variant={buttonVariants.primary}
          customStyles={{ flex: 1, marginHorizontal: 12 }}
          onPress={handleUpdateSettings}
        />
      </Box>
    </Box>
  );
}

FormSettingsModalContent.propTypes = {
  form: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleUpdateFormSettings: PropTypes.func.isRequired,
};

export default FormSettingsModalContent;
