import React from "react";
import { SafeAreaView, StatusBar, Text, View } from "react-native";

export default function Register() {
  return (
    <SafeAreaView
      style={{
        paddingTop: StatusBar.currentHeight,
        backgroundColor: "red",
        flex: 1,
      }}
    >
      <View>
        <Text>Welcome Register</Text>
      </View>
    </SafeAreaView>
  );
}
