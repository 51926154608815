import { FontAwesome } from "@expo/vector-icons";
import { Button } from "@mui/material";
import React, { useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";

import { fontColorDark, green } from "../../../theme/colors";
import PageLayout from "../../components/layouts/PageLayout";
import AddUserModal from "../../components/modals/AddUserModal";
import UserSettingsModal from "../../components/modals/UserSettingsModal";
import LSSpinner from "../../components/spinner/LSSpinner";
import LSText, { textVariant } from "../../components/text/LSText";
import useLocations from "../../hooks/useLocations";
import useUsers from "../../hooks/useUsers";
import { pageAppTypes } from "../constants";

const pageTitle = "Users Settings";

export default function UsersSettings() {
  const permissions = useSelector((state) => state.permissions);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [userSettingModalVisible, setUserSettingModalVisible] = useState(false);
  const { locations } = useLocations();
  const {
    users,
    loading,
    editUser,
    setEditUser,
    handleEditUserLocation,
    handleSaveEditUserChanges,
    setUsers,
  } = useUsers(locations);
  const handleAddNewUser = () => {
    setAddModalVisible(true);
  };

  const handleShowUserProfile = (user) => {
    setEditUser(user);
    setUserSettingModalVisible(true);
  };

  return (
    <PageLayout pageAppType={pageAppTypes.users}>
      <View style={styles.headerContainerStyles}>
        <LSText
          variant={textVariant.h1}
          text={pageTitle}
          color={fontColorDark}
        />
        {permissions.canAddUsers && (
          <Button
            sx={{
              borderRadius: "50%",
              padding: 2,
              height: "60px",
              width: "40px",
              backgroundColor: green[400],
              "&:hover": {
                backgroundColor: green[100],
                color: "#3c52b2",
              },
            }}
            onClick={handleAddNewUser}
          >
            <FontAwesome name="plus" size={25} color={fontColorDark} />
          </Button>
        )}
      </View>
      {loading ? (
        <LSSpinner />
      ) : (
        <View style={{ width: "100%" }}>
          {users?.map((user) => {
            const { uuid, firstName, lastName, position, active } = user;
            const inactiveText =
              active === null ? "Pending Invitation" : "Inactive";
            return (
              <Pressable
                key={`user-${uuid}`}
                onPress={() => handleShowUserProfile(user)}
              >
                <View style={styles.submissionContainer}>
                  <View style={styles.submissionContainerLeft}>
                    <LSText
                      variant={textVariant.h4}
                      text={`${lastName}, ${firstName}`}
                      color={fontColorDark}
                      customStyles={{ marginBottom: 8 }}
                    />
                    <LSText
                      variant={textVariant.text}
                      text={`${active ? "Active" : inactiveText}`}
                      color={fontColorDark}
                      customStyles={{ marginBottom: 8 }}
                    />
                    {user.locations?.map((location) => (
                      <LSText
                        key={`user-locations-${location.uuid}-${user.uuid}`}
                        variant={textVariant.text}
                        text={location.name}
                        color={fontColorDark}
                        customStyles={{ marginBottom: 8 }}
                      />
                    ))}
                  </View>
                  <View>
                    <LSText
                      variant={textVariant.h4}
                      text={position}
                      color={fontColorDark}
                      customStyles={{ marginBottom: 8 }}
                    />
                  </View>
                </View>
              </Pressable>
            );
          })}
        </View>
      )}
      <AddUserModal
        setUsers={setUsers}
        addModalVisible={addModalVisible}
        setAddModalVisible={setAddModalVisible}
      />
      <UserSettingsModal
        visible={userSettingModalVisible}
        setVisible={setUserSettingModalVisible}
        user={editUser}
        setUser={setEditUser}
        handleEditUserLocation={handleEditUserLocation}
        locations={locations}
        handleSaveEditUserChanges={handleSaveEditUserChanges}
      />
    </PageLayout>
  );
}

const styles = StyleSheet.create({
  spinnerContainerStyles: {
    height: "80vh",
    justifyContent: "center",
  },
  headerContainerStyles: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
  buttonsContainer: {
    flexDirection: "row",
    width: "100%",
  },
  inputStyles: { margin: 0, marginBottom: 8 },
  submissionContainer: {
    borderBottomColor: fontColorDark,
    borderBottomWidth: 1,
    flexDirection: "row",
    paddingTop: 16,
    paddingBottom: 8,
  },
  submissionContainerLeft: {
    flex: 1,
  },
});
