import PropTypes from "prop-types";
import React from "react";
import { View } from "react-native";
// eslint-disable-next-line import/no-extraneous-dependencies
import { SelectList } from "react-native-dropdown-select-list";

export default function LSDropdownPicker({
  _items,
  setValue,
  handleValueChange,
  name,
  answer,
  answerKey,
}) {
  const handleSetValue = (val) => {
    setValue((prevState) => ({
      ...prevState,
      [name]: val,
    }));
    handleValueChange(val);
  };

  const selectedOption =
    _items.find((item) => item.key === answer?.[answerKey]) || {};

  return (
    <View>
      <SelectList
        setSelected={handleSetValue}
        data={_items}
        save="key"
        boxStyles={{ borderRadius: 0, backgroundColor: "white" }}
        defaultOption={selectedOption}
        dropdownStyles={{ backgroundColor: "white" }}
      />
    </View>
  );
}

LSDropdownPicker.propTypes = {
  _items: PropTypes.array.isRequired,
  setValue: PropTypes.func.isRequired,
  handleValueChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  answer: PropTypes.object,
  answerKey: PropTypes.string,
};

LSDropdownPicker.defaultProps = {
  answer: {},
  handleValueChange: () => {},
  answerKey: "answerOptionUUID",
};
