import { useState, useEffect } from "react";

import { ADD_LOCATION, GET_LOCATIONS } from "../../shared/apiUrls";
import {
  apiGetAuth,
  apiPostAuth,
} from "../../shared/hoc/pagesWeb/incidentReporter/utils";

const newLocationObject = {
  name: "",
  address: "",
  city: "",
  state: "",
  zip: "",
};

const locationErrorsObject = {
  name: false,
  address: false,
  city: false,
  state: false,
  zip: false,
};

function useLocations(setAddModalVisible) {
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [locationErrors, setLocationErrors] = useState(locationErrorsObject);
  const [newLocation, setNewLocation] = useState(newLocationObject);

  const getLocations = async () => {
    const results = await apiGetAuth(GET_LOCATIONS);
    console.log({ results });
    setLocations(results);
    setLoading(false);
  };

  const validateLocationLocation = () => {
    let errors = false;

    const keys = Object.keys(newLocation);
    keys.forEach((key) => {
      if (!newLocation[key]) {
        setLocationErrors((prevState) => ({
          ...prevState,
          [key]: true,
        }));
        errors = true;
      } else {
        setLocationErrors((prevState) => ({
          ...prevState,
          [key]: false,
        }));
      }
    });

    return errors;
  };

  const handleGenerateLocationPress = async () => {
    const errors = validateLocationLocation();

    if (errors) return;

    const response = await apiPostAuth(ADD_LOCATION, newLocation);
    if (response.name) {
      setLocations((prevState) => [response, ...prevState]);
      setNewLocation(newLocationObject);
      setAddModalVisible(false);
    }
  };

  useEffect(() => {
    getLocations();
  }, []);

  return {
    locations,
    loading,
    locationErrors,
    newLocation,
    setNewLocation,
    handleGenerateLocationPress,
  };
}

export default useLocations;
