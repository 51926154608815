// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuid } from "uuid";

import { questionTypeCodes } from "../../../shared/hoc/pagesWeb/incidentReporter/constants";

export const emptyAnswer = ({ text = "", order = 0 }) => ({
  id: uuid(),
  text,
  order,
  error: false,
});

export const emptySwitch = ({ onText, offText, key, defaultValue }) => ({
  id: uuid(),
  key,
  onText,
  offText,
  onOffBool: defaultValue,
});

export const multipleChoiceEmptyQuestionError = {
  text: false,
  answerOptions: [],
};

export const multipleChoiceEmptyQuestion = {
  text: "",
  answerOptions: [
    emptyAnswer({ text: "", order: 0 }),
    emptyAnswer({ text: "", order: 1 }),
    emptyAnswer({ text: "", order: 2 }),
    emptyAnswer({ text: "", order: 3 }),
  ],
  otherOption: false,
  requiresAnswer: false,
  imageUpload: false,
  multipleAnswers: false,
  switches: [
    emptySwitch({
      key: "otherOption",
      onText: "'Other' option choice",
      offText: "No 'Other' option choice",
      defaultValue: false,
    }),
    emptySwitch({
      key: "requiresAnswer",
      onText: "Requires an answer",
      offText: "Does not require an answer",
      defaultValue: false,
    }),
    emptySwitch({
      key: "multipleAnswers",
      onText: "Allow multiple answers",
      offText: "Allow multiple answers",
      defaultValue: false,
    }),
  ],
  typeCode: questionTypeCodes.multiple,
};

export const dropDownEmptyQuestion = {
  text: "",
  answerOptions: [
    emptyAnswer({ text: "", order: 0 }),
    emptyAnswer({ text: "", order: 1 }),
    emptyAnswer({ text: "", order: 2 }),
    emptyAnswer({ text: "", order: 3 }),
  ],
  otherOption: false,
  requiresAnswer: false,
  imageUpload: false,
  multipleAnswers: false,
  switches: [
    emptySwitch({
      key: "otherOption",
      onText: "'Other' option choice",
      offText: "No 'Other' option choice",
      defaultValue: false,
    }),
    emptySwitch({
      key: "requiresAnswer",
      onText: "Requires an answer",
      offText: "Does not require an answer",
      defaultValue: false,
    }),
  ],
  typeCode: questionTypeCodes.multiple,
};

const textEmptyQuestion = {
  text: "",
  typeCode: questionTypeCodes.text,
  otherOption: false,
  requiresAnswer: false,
  imageUpload: false,
  switches: [
    emptySwitch({
      key: "requiresAnswer",
      onText: "Requires an answer",
      offText: "Does not require an answer",
      defaultValue: false,
    }),
  ],
};

const paragraphEmptyQuestion = {
  text: "",
  typeCode: questionTypeCodes.paragraph,
  otherOption: false,
  requiresAnswer: false,
  imageUpload: false,
  switches: [
    emptySwitch({
      key: "requiresAnswer",
      onText: "Requires an answer",
      offText: "Does not require an answer",
      defaultValue: false,
    }),
  ],
};

const calendarEmptyQuestion = {
  text: "",
  typeCode: questionTypeCodes.calendar,
  otherOption: false,
  requiresAnswer: false,
  imageUpload: false,
  switches: [
    emptySwitch({
      key: "requiresAnswer",
      onText: "Requires an answer",
      offText: "Does not require an answer",
      defaultValue: false,
    }),
  ],
};

const uploadEmptyQuestion = {
  text: "",
  typeCode: questionTypeCodes.upload,
  requiresAnswer: false,
  switches: [
    emptySwitch({
      key: "requiresAnswer",
      onText: "Requires an answer",
      offText: "Does not require an answer",
      defaultValue: false,
    }),
  ],
};

const dataSelectQuestion = {
  text: "",
  typeCode: questionTypeCodes.dataSelect,
  requiresAnswer: false,
  switches: [
    emptySwitch({
      key: "requiresAnswer",
      onText: "Requires an answer",
      offText: "Does not require an answer",
      defaultValue: false,
    }),
    emptySwitch({
      key: "multipleAnswers",
      onText: "Allow multiple answers",
      offText: "Allow multiple answers",
      defaultValue: false,
    }),
  ],
};

const emptyFormSenderQuestion = {
  text: "",
  typeCode: questionTypeCodes.formSender,
  requiresAnswer: false,
  switches: [
    emptySwitch({
      key: "requiresAnswer",
      onText: "Requires an answer",
      offText: "Does not require an answer",
      defaultValue: false,
    }),
    emptySwitch({
      key: "multipleAnswers",
      onText: "Allow multiple answers",
      offText: "Allow multiple answers",
      defaultValue: false,
    }),
  ],
};

export const emptyQuestion = {
  dropdown: dropDownEmptyQuestion,
  text: textEmptyQuestion,
  multiple: multipleChoiceEmptyQuestion,
  calendar: calendarEmptyQuestion,
  paragraph: paragraphEmptyQuestion,
  upload: uploadEmptyQuestion,
  dataSelect: dataSelectQuestion,
  formSender: emptyFormSenderQuestion,
};

export const emptyQuestionShort = {
  1: dropDownEmptyQuestion,
  2: textEmptyQuestion,
  3: multipleChoiceEmptyQuestion,
  4: calendarEmptyQuestion,
  5: paragraphEmptyQuestion,
  6: uploadEmptyQuestion,
  7: dataSelectQuestion,
  8: emptyFormSenderQuestion,
};

export const dataListOptions = [
  {
    key: 1,
    value: "Employees",
  },
  {
    key: 2,
    value: "Locations",
  },
];
